import React from "react";
import styled from 'styled-components';
import {colors} from "./shared-styles";
import {Link, useLocation} from "react-router-dom"
import logo from '../images/covidlogo (1).png'
import {BackButton} from "./buttons";
import {PrivacyPolicy} from "../pages/Settings";


const BottomNavContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 6vh;
  background-color: #EFEFEF;
  
  box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
  
  padding-bottom: env(safe-area-inset-bottom);
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  
  @media(min-width:800px) { 
    display: none;
  }
`;


// Create larger touch targets on mobile
const PaddedLink = styled(Link)`
  flex-basis: calc(25% - 1px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

const BottomNavIcon = styled.i`
  font-size: 2.5vh;
  color: ${(props) => props.selected ? colors.primary : colors.unselectedNavIcon};
`;

const BottomNav = ({links, ...props}) => {
    const location = useLocation();

    return (
        <BottomNavContainer>
            <PaddedLink to={links.home}>
                <BottomNavIcon selected={links.home === location.pathname} className="fas fa-home"/>
            </PaddedLink>
            <PaddedLink to={links.events}>
                <BottomNavIcon selected={links.events === location.pathname} className="fas fa-calendar"/>
            </PaddedLink>
            <PaddedLink to={links.settings}>
                <BottomNavIcon selected={links.settings === location.pathname} className="fas fa-bars"/>
            </PaddedLink>
        </BottomNavContainer>
    )
};


const TopNavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  
  height: 8vh;
  width: 100%;
  background-color:  #EFEFEF;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  box-shadow: 3px 3px 25px #0000001C;
  
  @media only screen and (max-width:800px) { 
    display: none;
  }
`;

const TopNavLink = styled(Link)`
  color: ${(props) => props.selected ? colors.primary : colors.lighterText};
  text-decoration: inherit;
  padding: 0.5em 1em;
  
   &:hover {
    background-color: #dfdfdf;
    border-radius: 15px;
  }
`;

const TopNavHeader = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2em;
`;

const TopNavMenuItemsContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const TopNavLogo = styled.img`
  object-fit: contain;
  height: 100%;
`;

const TopNav = ({links, ...props}) => {
    return (
        <TopNavContainer>
            <TopNavHeader>
                <TopNavLogo src={logo}/>
            </TopNavHeader>
            <TopNavMenuItemsContainer>
                <TopNavLink to={links.home}>Home</TopNavLink>
                <TopNavLink to={links.events}>Events</TopNavLink>
                <TopNavLink to={links.settings}>Settings</TopNavLink>
            </TopNavMenuItemsContainer>
        </TopNavContainer>
    )
};

const MobileHeaderContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #EFEFEF;
  border-bottom: 2px solid #CFCFCF;
  z-index: 1000;

  @media(min-width:800px) { 
    display: none;
  }
`;

const MobileHeaderContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2em;
`;

const MobileHeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em 0;
`;

const MobileHeaderLogo = styled.img`
  height: 100%;
`;

const MobileHeader = ({user, ...props}) => {
    return (
        <MobileHeaderContainer>
            <MobileHeaderContentContainer>
                <MobileHeaderTextContainer>
                    <BackButton/>
                </MobileHeaderTextContainer>
            </MobileHeaderContentContainer>
        </MobileHeaderContainer>
    )
};


const NavWrapperContainer = styled.div`
  
`;

const Content = styled.div`
  @media (min-width: 800px) {
        margin-top: 8vh;
  }
  
  @media (max-width: 800px) {
    margin-top: 10vh;
    margin-bottom: 6vh;
  }
`;


export default ({children, ...props}) => {
    const navLinks = {
        home: "/home",
        profile: "/profile",
        events: "/events",
        settings: "/settings"
    };

    return (
        <NavWrapperContainer>
            <TopNav links={navLinks}/>
            <MobileHeader/>
            <Content>
                {children}
            </Content>
            <BottomNav links={navLinks}/>
        </NavWrapperContainer>
    )
}
