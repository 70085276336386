import states from "../states";

export const commonInfo = {
    name: {
        name: "name",
        placeholder: "Your Full Name",
        label: "Name",
        type: "text",
        required: true
    },
    firstName: {
        name: "firstName",
        label: "First Name",
        type: "text",
        required: true
    },
    lastName: {
        name: "lastName",
        label: "Last Name",
        type: "text",
        required: true
    },
    email: {
        name: "email",
        type: "email",
        label: "Email",
        placeholder: "example@example.com",
        required: true,
        pattern: {value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, message:"* Please enter a valid email address"}
    },
    streetAddress: {
        name: "streetAddress",
        label: "Street Address",
        type: "text",
        placeholder: "1234 Somewhere Ave",
        required: true
    },
    zip: {
        name: "zip",
        label: "Zip Code",
        type: "text",
        placeholder: "00000",
        required: true,
        pattern: {value: /^[0-9]{5}(?:-[0-9]{4})?$/, message:"* Please enter a valid zip code."}
    },
    state: {
        name: "state",
        label: "State",
        type: "singleSelect",
        required: true,
        options: states
    },
    city: {
        name: "city",
        label: "City",
        type: "text",
        placeholder: "City",
        required: true
    },
    dateOfBirth: {
        name: "dateOfBirth",
        label: "Date of Birth",
        type: "date",
        required: true
    },
    gender: {
        name: "gender",
        label: "What are your preferred pronouns?",
        type: "singleSelect",
        required: true,
        options: [
            {name: 'gender', value: 'she', label: 'She/Her/Hers', description: ""},
            {name: 'gender', value: 'he', label: 'He/Him/His', description: ""},
            {name: 'gender', value: 'they', label: 'They/Them/Theirs', description: ""},
            {name: 'gender', value: 'preferNotToSay', label: 'Prefer Not To Answer', description: ""}
        ]
    },
    race: {
        name: "race",
        label: "Which category(s) best describe you?",
        required: true,
        type: "checkbox",
        options: [
            {
                value: "white",
                label: "White",
                description: "German, Irish, English, Italian, Polish, French, etc"
            },
            {
                value: "hispanic",
                label: "Hispanic, Latino or Spanish origin",
                description: "Mexican, Puerto Rican, Cuban, Salvadoran, etc"
            },
            {
                value: "black",
                label: "Black or African American",
                description: "African American, Jamaican, Haitian, Nigerian, Ethiopian, Somalian"
            },
            {
                value: "asian",
                label: "Asian",
                description: "Chinese, Filipino, Asian Indian, Vietnamese, Korean, Japanese"
            },
            {
                value: "americanIndian",
                label: "American Indian or Alaska Native",
                description: "Navajo nation, Blackfeet tribe, Mayan, Aztec, Native Village"
            },
            {
                value: "middleEastern",
                label: "Middle Eastern or North African",
                description: "Lebanese, Iranian, Egyptian, Syrian, Moroccan, Algerian etc"
            },
            {
                value: "nativeHawaiian",
                label: "Native Hawaiian or Other Pacific Islander",
                description: "Hawaiian, Samoan, Chamorro, Tongan, Fijian, etc"
            },
            {
                value: "otherRace",
                label: "Some other race, ethnicity or origin",
                description: ""
            }],
        validate: (value) => {return value.length !== 0}
    },
    religion: {
        name: "religion",
        label: "What religious family do you belong to or identify yourself most close to?",
        type: "singleSelect",
        options: [
            {value: "asianFolk", label: "Asian Folk Religion", description:""},
            {value: "hindu", label: "Hindu", description:""},
            {value: "jewish", label: "Jewish", description:""},
            {value: "muslim", label: "Muslim", description:""},
            {value: "christian", label: "Christian", description: "Catholic, protestant or any other Christian denominations"},
            {value: "otherReligion", label: "Other", description:""},
            {value: "notReligious", label: "I am not religious", description:""}
        ]
    }
};