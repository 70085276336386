import React from "react";
import styled, {css} from 'styled-components';
import {colors} from './shared-styles';
import {useHistory} from "react-router-dom"

const sharedButtonCss = css`
    width: 100%;
    border-radius: 2em;
    font-size: 1em;
    padding: calc(0.5em - 1px) 1em;
    text-transform: capitalize;
    text-align: center;
    opacity: 1;
    height: 50px;
`;

export const PrimaryButton = styled.button`
    ${sharedButtonCss};
    background-color: ${props => props.isDanger ? colors.danger : colors.primary};
    color: #FFFFFFDE;
    border: 0;
`;

export const SecondaryButton = styled.button`
    ${sharedButtonCss};
    background: #FFFFFF 0 0 no-repeat padding-box;
    border: 2px solid #1E424C;
`;

export const BackIcon = styled.i`
  font-size: 1.5em;
  color: ${colors.primary}
`;

export const BackButton = ({...props}) => {
    const history = useHistory();
    return (
        <BackIcon
            onClick={() => history.goBack()}
            className = "fas fa-arrow-left"
        />
    )
};