export const healthAssessment = {
    respiratory: {
        name: "respiratory",
        label: "Do you have a fever, shortness of breath, or cough?",
        type: "yesNo",
        required: true
    },
    elderly: {
        name: "elderly",
        label: "Do you live with or care for someone over the age of 65 years of age?",
        type: "yesNo",
        required: true
    },
    preexistingConditions: {
        name: "preexistingConditions",
        label: "Do you have any of these conditions? If not, leave empty.",
        type: "checkbox",
        required: false,
        options: [
            {value: "asthma", label: "Asthma or Chronic Lung Failure"},
            {value: "pregnancy", label: "Pregnancy"},
            {value: "kidney", label: "Kidney Failure"},
            {value: "heart", label: "Heart Failure"},
            {value: "diabetes", label: "Diabetes"},
            {value: "weakened", label: "Weakened Immune System"},
        ]
    },
    international: {
        name: "international",
        label: "In the last 14 days have you travelled internationally?",
        type: "yesNo",
        required: true
    },
    exposure: {
        name: "exposure",
        label: "In the last 14 days have you been exposed to someone with COVID-19?",
        type: "yesNo",
        required: true
    },
    medicalProfession: {
        name: "medicalProfession",
        label: "Do you live or work in a health care facility, nursing home, or assisted living facility?",
        type: "yesNo",
        required: true
    },
    airportProfession: {
        name: "airportProfession",
        label: "Do you work at the airport?",
        type: "yesNo",
        required: true
    },
    smoker: {
        name: "smoker",
        label: "Do you smoke or use vape?",
        type: "yesNo",
        required: true
    },
    childcare: {
        name: "childcare",
        label: "Do you care for children or run a daycare service?",
        type: "yesNo",
        required: true
    },
    resultMethod: {
        name: "resultMethod",
        label: "How would you like to receive your test results?",
        type: "radio",
        required: true,
        options: [
            {value: "text", label: "Text Message"},
            {value: "phone", label: "Phone Call"},
            {value: "noPreference", label: "Don't Care"},
        ]
    },
    volunteerInterest: {
        name: "volunteerInterest",
        label: "Would you be interested in volunteering for future pop-ups?",
        type: "yesNo",
        required: true
    },
};

export const healthAssessmentForm = [
    healthAssessment.respiratory,
    healthAssessment.elderly,
    healthAssessment.preexistingConditions,
    healthAssessment.international,
    healthAssessment.exposure,
    healthAssessment.medicalProfession,
    healthAssessment.airportProfession,
    healthAssessment.smoker,
    healthAssessment.childcare,
    healthAssessment.resultMethod,
    healthAssessment.volunteerInterest,
];