import {CallToAction, Page} from "../common-components/ImagePageWithContent";
import React, {useContext} from "react";
import styled from "styled-components";
import {useHistory} from 'react-router-dom';
import "../common-components/DatePickerStyles.css"
import {db, analytics} from "../firebase";
import {AppContext} from "../store";
import GenericForm from "../common-components/GenericForm";
import {patientInfoForm} from "../forms/patient-info";


const DemographicsPageContainer = styled(Page)`
  padding-bottom: 3em;
`;

export default ({...props}) => {
    const [globalState, globalDispatch] = useContext(AppContext);
    const history = useHistory();

    const onSubmit = async (value, e) => {
        await db.collection('users').doc(globalState.user).update({
            profile: value
        });
        analytics.logEvent('patient_info_entered')
        history.push("/register/notifications");
    };

    return (
        <DemographicsPageContainer>
            <CallToAction
                header="Demographics"
                text="Tell us a little more about yourself"
            />
            <GenericForm
                fields={patientInfoForm}
                onSubmit={onSubmit}
            />
        </DemographicsPageContainer>
    )
}
