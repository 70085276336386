import ImagePageWithContent from "../common-components/ImagePageWithContent";
import React from "react";
import thankYouImage from "../images/thank-you.svg"
import {PrimaryButton} from "../common-components/buttons";
import {useHistory} from "react-router-dom";


const thankYouCopy = {
    register: {header: "Thank You", page: "/home", body:"We highly appreciate the time you took to register with us"},
    assessment: {header: "Thank You", page: "/home", body:"Thanks for completing the health assessment"},
    deleted: {header: "Delete Successful", page: "/", body:"Your account was successfully deleted"}
};

export default ({...props}) => {
    const history = useHistory();
    const config = props.thankfulFor ? thankYouCopy[props.thankfulFor] : thankYouCopy.register;
    return (
        <ImagePageWithContent
            imageSource={thankYouImage}
            callToActionHeader={config.header}
            callToActionText={config.body}
        >
            <PrimaryButton
                onClick={() => history.push(config.page)}
            >
                Done
            </PrimaryButton>
        </ImagePageWithContent>
    )
}
