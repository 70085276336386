import React, { useContext, useEffect, useState, useRef } from "react";
import { CallToAction, Page } from "../common-components/ImagePageWithContent";
import styled from "styled-components";
import { Card, colors } from "../common-components/shared-styles"
import { Redirect, useHistory } from "react-router-dom";
import NavWrapper from "../common-components/NavWrapper";
import { AppContext } from "../store";
import { LoadingIcon } from "../common-components/Loader";
import { PrivacyPolicy } from "./Settings";
import { db } from "../firebase";
import { PlaidLink } from 'react-plaid-link';
import firebase from 'firebase/app';
import ScaleLoader from "react-spinners/ScaleLoader";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Icon, Tooltip, IconButton} from '@material-ui/core'


const NumberInCircleContainer = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1em;
  width: 25px;
  height: 25px;
  align-items: center;
  background: ${colors.primary};
  color: ${colors.white};
  flex: 0 0 25px;
`;

const NumberInCircle = ({ value, ...props }) => {
    return (
        <NumberInCircleContainer>
            {value}
        </NumberInCircleContainer>
    )
};

const NumberedCardContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  padding: 1em;
  align-items: start;
  min-height: 100px;
  margin-bottom: 1em;
`;

const NumberedCardContentContainer = styled.div``;


const NumberedCard = ({ value, onClick, ...props }) => {
    return (
        <NumberedCardContainer onClick={onClick}>
            <NumberInCircle value={value} />
            <NumberedCardContentContainer>
                {props.children}
            </NumberedCardContentContainer>
        </NumberedCardContainer>
    )
};

const CardContentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1em;
`;

const CardContentsHeader = styled.div`
  font-weight: bold;
  margin-bottom: .5em;
`;

const CardContents = styled.div`

`;

const Cards = styled.div`
  width: 80%;
`;

const HeaderBar = styled.div`
  width: 100%;
  background: #F8F8F8;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const UserProfileContainer = styled.div`
  font-size: 5em;
`;

const UserProfileIcon = ({ ...props }) => {
    return (
        <UserProfileContainer>
            <i className="fas fa-user-circle"></i>
        </UserProfileContainer>
    )
};

const HomePageContainer = styled(Page)`
  justify-content: center;
`;

const getCardsToShow = (userInfo) => {
    let cards = [];

    if (!userInfo?.assessment) {
        cards = [...cards, 'assessment']
    }

    if (!userInfo?.volunteerProfile && userInfo?.assessment?.volunteerInterest === 'yes') {
        cards = [...cards, 'volunteerForm']

    }
    if (userInfo?.volunteerProfile) {
        cards = [...cards, 'volunteerSignupLink']
        if ((userInfo?.volunteerProfile.rewardType === "Student Loan Debt Relief") && userInfo?.accessToken) {
            cards = [...cards, 'syncedStudentLoan']
        }
        if ((userInfo?.volunteerProfile.rewardType === "Emergency Savings Deposit") && userInfo?.accessToken) {
            cards = [...cards, 'syncedEmergencySavings']
        }
        if ((userInfo?.volunteerProfile.rewardType === "Student Loan Debt Relief") && !userInfo?.accessToken) {
            cards = [...cards, 'studentLoan']
        }
        else if ((userInfo?.volunteerProfile.rewardType === "Emergency Savings Deposit") && !userInfo?.accessToken) {
            cards = [...cards, 'emergencySavings']
        }

    }

    cards = [...cards, 'volunteerActivity'];
    cards = [...cards, 'events'];



    return cards;
};

function disconnectAccount(userID){
    let disconnect = firebase.functions().httpsCallable('disconnectAccount');
    return disconnect({userID:userID});
}

function RenderStudentLoan(link_token) {

    const [loading, setLoading] = useState(false);
    const [responseObject, setResponse] = useState(undefined)

    const contextValue = useContext(AppContext)
    const userUID = contextValue[0].user;

    let plaidIntegration
    let accountField

    const handleClick = (token, metadata) => {
        setLoading(true)
        let plaidIntegrate = firebase.functions().httpsCallable('plaidIntegrate');
        plaidIntegrate({ public_token: token, uid: userUID, account_id: metadata.account_id }).then(function (response) {
            if (response.data.status_code == "200") {
                setLoading(false);
                setResponse(response);
            }
        }).catch(function (error) {
            const code = error.code;
            const message = error.message;
            const details = error.details;
            setResponse(null);
            setLoading(false);
        });

    }

    if ((responseObject === undefined) && (loading === false)) {
        plaidIntegration =<PlaidLink
            token={link_token.token}
            onSuccess={handleClick}
            style={{backgroundColor:'#4BB543', borderRadius: '10px', color: 'white', width: '50%', marginLeft: '-5px'}}
        >
            Connect your account 
    </PlaidLink>
        accountField = "No connected bank account"
    }
    else if ((responseObject === undefined) && (loading === true)) {
        plaidIntegration = <span>Syncing your account...</span>;
        accountField = <ScaleLoader color='#1E424C' css={{ display: 'inline-block' }} height={10}></ScaleLoader>
    }
    else if ((responseObject === null) && (loading === false)) {
        accountField = <span style={{ display: 'inline-block',fontWeight:'normal' }} >An error has occured</span>
    }
    else {
    }

    return (
        <div>
            <CardContentsHeader style={{width:'110%'}}>
                Student Loan Balance: <span style={{fontSize:'14px', fontWeight:'normal'}}> {accountField}</span>
            </CardContentsHeader> 
            <CardContents style={{marginBottom: ".5em"}}>
                Powered by Plaid.com
            </CardContents>
            {plaidIntegration}
        </div>
    );
}


function RenderEmergencySavings(link_token) {

    const [loading, setLoading] = useState(false);
    const [responseObject, setResponse] = useState(undefined)
    const contextValue = useContext(AppContext)
    const userUID = contextValue[0].user;

    let plaidIntegration
    let accountField


    const handleClick = (token, metadata) => {
        setLoading(true)
        let plaidIntegrate = firebase.functions().httpsCallable('plaidIntegrate');
        plaidIntegrate({ public_token: token, uid: userUID, account_id: metadata.account_id }).then(function (response) {
            if (response.data.status_code == "200") {
                setLoading(false);
                setResponse(response);
            }
        }).catch(function (error) {
            const code = error.code;
            const message = error.message;
            const details = error.details;
            setResponse(null);
            setLoading(false);
        });
    }

    if ((responseObject === undefined) && (loading === false)) {
        plaidIntegration = <PlaidLink
            token={link_token.token}
            onSuccess={handleClick}
            style={{backgroundColor:'#4BB543', borderRadius: '10px', color: 'white', width: '50%',marginLeft: '-5px'}}
        >
            Connect your account
    </PlaidLink>
        accountField = "No connected bank account"
    }
    else if ((responseObject === undefined) && (loading === true)) {
        plaidIntegration = <span>Syncing your account...</span>;
        accountField = <ScaleLoader color='#1E424C' css={{ display: 'inline-block' }} height={10}></ScaleLoader>
    }
    else if ((responseObject === null) && (loading === false)) {
        accountField = <span style={{ display: 'inline-block',fontWeight:'normal' }} >An error has occured</span>
    }
    else {
    }

    return (
        <div>
            <CardContentsHeader style={{width:'110%'}}>
                Emergency Savings Deposit: <span style={{fontSize:'14px', fontWeight:'normal'}}> {accountField} </span>
            </CardContentsHeader> 
            <CardContents style={{marginBottom: ".5em"}}>
                Powered by Plaid.com 
            </CardContents>
            {plaidIntegration}
        </div>
    );
}

function RenderSyncedEmergencySavings() {
    const abortController = new AbortController()
    const [account, setAccount] = useState(undefined);
    const [disconnectObject, setDisconnectObject] = useState(null);
    const btnRef = useRef();

    const contextValue = useContext(AppContext)
    const accessToken = contextValue[0].userInfo.accessToken;
    const userUID = contextValue[0].user;
    let balance
    let plaidIntegration


    useEffect(() => {
        let plaidGetBalance = firebase.functions().httpsCallable('plaidGetBalance');
        plaidGetBalance({ accessToken: accessToken, userID:userUID }).then(function (response) {
            setAccount(response.data.accounts[0]);
        }).catch(function (error) {
            const code = error.code;
            const message = error.message;
            const details = error.details;
            setAccount(null);
        });
        return function cleanup() {
            abortController.abort()
        }
    }, []);

    if (account === undefined) {
        plaidIntegration = <span>Loading account balance...</span>;
        balance = <ScaleLoader color='#1E424C' css={{ display: 'inline-block' }} height={10}></ScaleLoader>
    }
    else if (account === null) {
        balance = <span style={{ display: 'inline-block' }} >An error has occured</span>
    }
    else {
        plaidIntegration =  <span>
            {disconnectObject}
            <IconButton 
                aria-label="disconnect"
                ref={btnRef} 
                onClick={async ()=>{
                    if(btnRef.current){
                        btnRef.current.setAttribute("disabled", "disabled");
                    }
                    let status;
                    setDisconnectObject("Disconnecting...")
                    status = await disconnectAccount(userUID)
                    if(status.data.removed === true){
                        console.log("True after function")
                    }
                    else{
                        setDisconnectObject("An error has occured")
                        console.log("False after function")
                    }
                }
                } 
                style={{ marginLeft: "520px" }}>
            <Tooltip title="Disconnect Account" arrow><DeleteForeverIcon/></Tooltip>
            </IconButton>
        </span> 
        balance = "$" + account.balances.current
    }

    return (
        <div>
            <CardContentsHeader>
                Emergency Savings Deposit: <span style={{fontWeight:'normal'}}> {balance} </span>
            </CardContentsHeader>
            <CardContents style={{marginBottom: ".5em"}}>
                Powered by Plaid.com
            </CardContents>
            {plaidIntegration}
        </div>
    )

}


function RenderSyncedStudentLoan() {
    const abortController = new AbortController()
    const [account, setAccount] = useState(undefined);
    const contextValue = useContext(AppContext)
    const btnRef = useRef();

    const accessToken = contextValue[0].userInfo.accessToken;
    const userUID = contextValue[0].user;
    const [disconnectObject, setDisconnectObject] = useState(false);

    let balance
    let plaidIntegration


    useEffect(() => {
        let plaidGetBalance = firebase.functions().httpsCallable('plaidGetBalance');
        plaidGetBalance({ accessToken: accessToken, userID:userUID }).then(function (response) {
            setAccount(response.data.accounts[0]);
        }).catch(function (error) {
            const code = error.code;
            const message = error.message;
            const details = error.details;
            setAccount(null);
        });

        return function cleanup() {
            abortController.abort()
        }
    }, []);

    if (account === undefined) {
        plaidIntegration = <span>Loading account balance...</span>;
        balance = <ScaleLoader color='#1E424C' css={{ display: 'inline-block' }} height={10}></ScaleLoader>
    }
    else if (account === null) {
        balance = <span style={{ display: 'inline-block' }} >An error has occured</span>
    }
    else {
        plaidIntegration = <span>
            {disconnectObject}
            <IconButton 
                aria-label="disconnect" 
                ref={btnRef}
                onClick={async ()=>{
                    if(btnRef.current){
                        btnRef.current.setAttribute("disabled", "disabled");
                    }
                    let status;
                    setDisconnectObject("Disconnecting...")
                    status = await disconnectAccount(userUID)
                    if(status.data.removed === true){
                        console.log("True after function")
                    }
                    else{
                        setDisconnectObject("An error has occured")
                        console.log("False after function")
                    }
                }
                } 
                style={{ marginLeft: "520px" }}>
            <Tooltip title="Disconnect Account" arrow><DeleteForeverIcon/></Tooltip>
            </IconButton>
        </span> 
        balance = "$" + account.balances.current
    }

    return (
        <div>
            <CardContentsHeader>
                Student Loan Balance: <span style={{fontWeight:'normal'}}> {balance} </span>
            </CardContentsHeader>
            <CardContents style={{marginBottom: ".5em"}}>
                Powered by Plaid.com
            </CardContents>
            {plaidIntegration}
        </div>
    )

}

const renderCard = (type, number, history, link_token, email="default@address.com") => {
    switch (type) {
        case 'assessment':
            return (
                <NumberedCard key={number} value={number} onClick={() => history.push('/assessment')}>
                    <CardContentsContainer>
                        <CardContentsHeader>
                            Get Tested
                        </CardContentsHeader>
                        <CardContents>
                            Take this health assessment quiz first
                        </CardContents>
                    </CardContentsContainer>
                </NumberedCard>
            );
        case 'volunteerForm':
            return (
                <NumberedCard key={number} value={number} onClick={() => history.push('/register/volunteer')}>
                    <CardContentsContainer>
                        <CardContentsHeader>
                            Become a Volunteer
                        </CardContentsHeader>
                        <CardContents>
                            Complete this interest form
                        </CardContents>
                    </CardContentsContainer>
                </NumberedCard>
            );
        case 'volunteerSignupLink':
            return (
                <NumberedCard key={number} value={number}
                    onClick={() => window.open("https://signup.com/group/276058530012")}>
                    <CardContentsContainer>
                        <CardContentsHeader>
                            Volunteer Activity
                        </CardContentsHeader>
                        <CardContents>
                            Register for an Event
                        </CardContents>
                    </CardContentsContainer>
                </NumberedCard>
            );
        case 'volunteerActivity':
            return (
                <NumberedCard key={number} value={number} onClick={() => history.push(`/viewActivity/${email}`)} >
                    <CardContentsContainer>
                        <CardContentsHeader>
                            Volunteer Activity History
                        </CardContentsHeader>
                        <CardContents>
                            View a list of all your volunteer activity and points to date.
                        </CardContents>
                    </CardContentsContainer>
                </NumberedCard>
            );
        case 'studentLoan':

            return (
                <NumberedCard key={number} value={number} >
                    <CardContentsContainer>
                        <RenderStudentLoan token={link_token}></RenderStudentLoan>
                    </CardContentsContainer>
                </NumberedCard>
            );
        case 'emergencySavings':
            return (
                <NumberedCard key={number} value={number}>
                    <CardContentsContainer>
                        <RenderEmergencySavings token={link_token}></RenderEmergencySavings>

                    </CardContentsContainer>
                </NumberedCard>
            );
        case 'syncedStudentLoan':
            return (
                <NumberedCard key={number} value={number}>
                    <CardContentsContainer>
                        <RenderSyncedStudentLoan></RenderSyncedStudentLoan>
                    </CardContentsContainer>
                </NumberedCard>
            );
        case 'syncedEmergencySavings':
            return (
                <NumberedCard key={number} value={number}>
                    <CardContentsContainer>
                        <RenderSyncedEmergencySavings></RenderSyncedEmergencySavings>
                    </CardContentsContainer>
                </NumberedCard>
            );
        case 'events':
            return (
                <NumberedCard key={number} value={number} onClick={() => history.push('/events')}>
                    <CardContentsContainer>
                        <CardContentsHeader>
                            View Popup Events
                        </CardContentsHeader>
                        <CardContents>
                            Check out the list of upcoming Popup Testing Events
                        </CardContents>
                    </CardContentsContainer>
                </NumberedCard>
            );
        default:
            return (<div />)
    }
};

export default ({...props}) => {
    const history = useHistory();
    const [globalState, globalDispatch] = useContext(AppContext);
    const [linkToken, setToken] = useState(props.linkToken);
    let cardObject;
    useEffect(() => {
        const updateUserInfo = async () => {
            if (globalState.user) {
                const ref = await db.collection("users").doc(globalState.user).get();
                if (ref.exists) {
                    globalDispatch({ type: 'USER_INFO_UPDATED', userInfo: ref.data() });
                }
            }
        };

        if (!globalState.userInfo) {
            updateUserInfo();
        }
    }, [globalState.userInfo]);

    if(globalState.userInfo && globalState.userInfo.volunteerProfile){
        cardObject = <Cards> {getCardsToShow(globalState.userInfo,props.linkToken).map((card, index) => renderCard(card, index + 1, history,props.linkToken,globalState.userInfo.volunteerProfile.email))}<PrivacyPolicy /></Cards>
    }
    else if (globalState.userInfo && globalState.userInfo.profile){
        cardObject = <Cards> {getCardsToShow(globalState.userInfo,props.linkToken).map((card, index) => renderCard(card, index + 1, history,props.linkToken,globalState.userInfo.profile.email))}<PrivacyPolicy /></Cards>
    }
    else{
        cardObject = <Cards> {getCardsToShow(globalState.userInfo,props.linkToken).map((card, index) => renderCard(card, index + 1, history,props.linkToken))}<PrivacyPolicy /></Cards>
    }

    return (
        <NavWrapper>
            <HomePageContainer>
                <CallToAction
                    header="Choose one of the following"
                />
                {!globalState.user && <Redirect to="/" />}
                {!globalState.userInfo && <LoadingIcon />}
                {cardObject}
            </HomePageContainer>
        </NavWrapper>
    )
}
