import {commonInfo} from "./common-info";
import {healthAssessment} from "./health-assessment";
import {volunteer} from "./volunteer";

const formConfig = {
    ...commonInfo,
    ...healthAssessment,
    ...volunteer,
};

export default formConfig;