import React, {useContext, useEffect, useState} from "react";
import {analytics, getCsvExport, getPatientCsvExport, getVolunteerCsvExport, updateEvents} from "../firebase";
import {PrimaryButton} from "../common-components/buttons";
import ImagePageWithContent, {Page} from "../common-components/ImagePageWithContent";
import image from '../images/verification-code.svg'
import NavWrapper from "../common-components/NavWrapper";
import {AppContext} from "../store";
import {useHistory} from "react-router-dom";
import {db} from '../firebase';
import {LoadingIcon} from "../common-components/Loader";
import styled from 'styled-components';

import formConfig from "../forms";

const ContentContainer = styled.div`
  display: flex;
  //flex-direction: column;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Admin = () => {

    const history = useHistory();
    const [state, dispatch] = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [patientUrl, setPatientUrl] = useState();
    const [volunteerUrl, setVolunteerUrl] = useState();

    useEffect(() => {
        const userIsAdmin = async () => {
            if (state.authChecked) {
                if (state.user) {
                    const ref = await db.collection('admins').doc(state.user).get();
                    if (!ref.exists) {
                        history.push("/home");
                    }
                } else {
                    history.push("/")
                }
            }
        };

        userIsAdmin()
    });

    const [error, setError] = useState(null);
    const downloadCsv = (getCsvExportFunction, setUrl) => async (type) => {
        setLoading(true);
        analytics.logEvent('csv_export_initiated');
        try {
            const result = await getCsvExportFunction();
            setLoading(false);
            if (result) {
                setUrl(result.data.fileUrl);
                window.open(result.data.fileUrl);
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const triggerUpdate = async () => {
        setLoading(true);
        try{
            await updateEvents();
            setLoading(false);
        } catch(e){
            console.error(e);
            setLoading(false);
        }
    }

    return (
        <NavWrapper>
            <ImagePageWithContent
                callToActionHeader="Admin"
                callToActionText={error}
                imageSource={image}
            >
                <ContentContainer>
                    <ButtonsContainer>
                        {!loading &&
                        <PrimaryButton onClick={downloadCsv(getPatientCsvExport, setPatientUrl)}>
                            Export Patients to CSV
                        </PrimaryButton>
                        }
                        <br/>
                        {(!loading && patientUrl) &&
                        <div style={{textAlign: "center"}}>
                            The file will download in a popup window, if it doesn't. <a href={patientUrl}> Click Here</a>
                        </div>
                        }
                        {!loading &&
                        <PrimaryButton onClick={downloadCsv(getVolunteerCsvExport, setVolunteerUrl)}>
                            Export Volunteers to CSV
                        </PrimaryButton>
                        }
                        {(!loading && volunteerUrl) &&
                        <div style={{textAlign: "center"}}>
                            The file will download in a popup window, if it doesn't. <a href={volunteerUrl}> Click Here</a>
                        </div>
                        }
                        <br/>
                        {!loading &&
                        <PrimaryButton onClick={triggerUpdate}>
                            Sync Events from Eventbrite
                        </PrimaryButton>
                        }

                    </ButtonsContainer>

                    {loading &&
                    <LoadingIcon/>
                    }
                </ContentContainer>
            </ImagePageWithContent>
        </NavWrapper>
    )
};

export default Admin;
