import * as firebase from "firebase/app";
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyA7NSuwqPYxGt3HhxuqJlvqm8zAWghkUko",
    authDomain: "mycovidmd-dev.firebaseapp.com",
    databaseURL: "https://mycovidmd-dev.firebaseio.com",
    projectId: "mycovidmd-dev",
    storageBucket: "mycovidmd-dev.appspot.com",
    messagingSenderId: "250540489413",
    appId: "1:250540489413:web:8ca0fc8043de1196b2ceac",
    measurementId: "G-46GBJX8WJH"
};

const firebasedevConfig = {
    projectId: "mycovidmd-dev-2b22e",
    appId: "1:319663418364:web:9f307a7a89d50436dae079",
    databaseURL: "https://mycovidmd-dev-2b22e.firebaseio.com",
    storageBucket: "mycovidmd-dev-2b22e.appspot.com",
    locationId: "us-central",
    apiKey: "AIzaSyBfnI0fzASIehiizGCwrHDKCcwvMyBvUg4",
    authDomain: "mycovidmd-dev-2b22e.firebaseapp.com",
    messagingSenderId: "319663418364",
    measurementId: "G-8V81FWFY5M"
}

const prodFirebaseConfig = {
    apiKey: "AIzaSyDtnNwJ5iQagsuYWsFldRJFCGPi4htnWcU",
    authDomain: "turing-link-238120.firebaseapp.com",
    databaseURL: "https://turing-link-238120.firebaseio.com",
    projectId: "turing-link-238120",
    storageBucket: "turing-link-238120.appspot.com",
    messagingSenderId: "534251886124",
    appId: "1:534251886124:web:a064d7d7cb2ef078cca389",
    measurementId: "G-BGRH8YZSBY"
};

console.log(`App running in ${process.env.REACT_APP_ENVIRONMENT} mode`);

if(process.env.REACT_APP_ENVIRONMENT === 'production'){
    firebase.initializeApp(prodFirebaseConfig);
} else {
    firebase.initializeApp(firebasedevConfig);
}


export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const db = firebase.firestore();


export const getPatientCsvExport = firebase.functions().httpsCallable("exportPatients");
export const getVolunteerCsvExport = firebase.functions().httpsCallable("exportVolunteers");
export const updateEvents = firebase.functions().httpsCallable("triggerEventsUpdate");
export const plaidIntegrate = firebase.functions().httpsCallable("plaidIntegrate");
export const getPlaidToken = firebase.functions().httpsCallable("generatePlaidLinkToken");
