import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {analytics, db} from "../firebase";
import {colors} from "../common-components/shared-styles";
import dayjs from "dayjs";
import {LoadingIcon} from "../common-components/Loader";
import NavWrapper from "../common-components/NavWrapper";

const EventPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 95vh;
`;

const EventCards = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin:-10px;
`;

const EventCardContainer = styled.div`
    background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 3px 3px 25px #0000001C;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 300px;
  margin: 10px;
`;


const EventText = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const EventImageStyled = styled.img`
  flex: 0 0 20%;
  //max-width: 20%;
  width: 100%;  
  object-fit: cover;
  border-radius: 9px 9px 0 0;
`;

const EventImage = ({...props}) => {
    return (
        <EventImageStyled {...props}/>
    )
};

const EventTitle = styled.span`
  font-size: 2vh;
`;
const EventDate = styled.span`
  text-transform: uppercase;
  font-size: 1.4vh;
  color: ${colors.lighterText}
`;

const EventLocationContainer = styled.div`
  font-size: 1.4vh;
  color: ${colors.lighterText}
`;

const EventLocation = ({...props}) => {
    return (
        <EventLocationContainer>
            <i style={{paddingRight: "0.5em"}} className="fas fa-map-marker-alt"/>
            {props.children}
        </EventLocationContainer>
    )
};

const prettifyDate = (utcDate) => {
    return dayjs(utcDate).format("ddd, MMM D h:mm A");
};

const EventCard = ({event, ...props}) => {
    return (
        <EventCardContainer onClick={() => window.open(event.url)}>
            <EventImage src={event.logo}/>
            <EventText>
                <EventDate>
                    {prettifyDate(event.startsAt)}
                </EventDate>
                <EventTitle>
                    {event.name}
                </EventTitle>
                <EventLocation>{event.location}</EventLocation>
            </EventText>
        </EventCardContainer>
    )
};

const NoEventsText = styled.article`
  width: 70%;
`;

export default ({}) => {

    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [previousEvents, setPreviousEvents] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const loadEvents = async () => {
            analytics.logEvent('events_page_viewed');
            const querySnapshot = await db.collection('events').orderBy("startsAt", "desc").get();
            const events = querySnapshot.docs.map(d => d.data());

            const [previous, upcoming] = events.reduce(([previous, upcoming], event) => {
                const startsAt = dayjs(event.startsAt).valueOf()
                if (startsAt > Date.now()) {
                    return [previous, [event, ...upcoming]];
                } else {
                    return [[event, ...previous], upcoming];
                }
            }, [[], []]);
            setPreviousEvents(previous.slice(-2));
            setUpcomingEvents(upcoming);
            setLoaded(true);
        };

        loadEvents();
    }, [loaded]);

    return (
        <NavWrapper>
            <EventPageContainer>
                <h2>Upcoming Events</h2>
                {!loaded && <LoadingIcon/>}
                {upcomingEvents.length > 0 && loaded ?
                    <EventCards>
                        {upcomingEvents.map(event => <EventCard key={event.id} event={event}/>)}
                    </EventCards>
                    : <NoEventsText> No events scheduled just yet, but check back weekly</NoEventsText>}
                {(upcomingEvents.length === 0 && loaded) && <h2>Previous Events</h2>}
                {upcomingEvents.length === 0 && <EventCards>
                    {previousEvents.map(event => <EventCard key={event.id} event={event}/>)}
                </EventCards>}
            </EventPageContainer>
        </NavWrapper>
    )
};
