import React from "react";
import styled from 'styled-components';


export const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 95vh;
  padding-top: 100px;
  padding-bottom: 100px;`;


const LogoAndTextContainer = styled.div`
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 60%;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 90%;
`;

const LogoImage = styled.img`
  max-width: 90%;
  margin: auto;
`;

export const CallToAction = ({header, text}) => {
    return (
        <CallToActionContainer>
            <h2>
                {header}
            </h2>
            <p>
                {text}
            </p>
        </CallToActionContainer>
    )
};

const CallToActionContainer = styled.article`
  text-align: center;
`;

const CallToActionText = styled.p`
  max-width: 80%;
`;

const Content = styled.div`
  width: 80%;
  flex-basis: 40%;
`;

export default ({imageSource, callToActionHeader, callToActionText, callToActionComponent, children}) => {
    return (
        <Page>
            <LogoAndTextContainer>
                <LogoContainer>
                    <LogoImage src={imageSource}/>
                </LogoContainer>
                <CallToAction
                    header={callToActionHeader}
                    text={callToActionText}
                />
            </LogoAndTextContainer>
            <Content>
                {children}
            </Content>
        </Page>
    );
};
