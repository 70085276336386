import React from "react";
import {Controller} from "react-hook-form";
import FormGroup, {FormContainer, FormGroupContainer, FormGroupLabel} from "./FormGroup";
import {MultiSelect, SingleSelect, YesNoRadioInput} from "./inputs";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import {commonInputCss} from "./shared-styles";
import states from "../states";

const DateInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
`;
const TextAreaDiv = styled.textarea`
  ${commonInputCss};
  min-height: 2.5em;
`;

export const TextAreaInput = ({ref, label, selected, name, id, handleChange, type, errors, ...props}) => {
    const onChange = (e) => {
        props.setValue(name, e.target.value)
    }
    return(
    <FormGroupContainer>
        <FormGroupLabel error={errors?.[name]}>
            {label}{errors?.[name]?.message}
        </FormGroupLabel>
        <TextAreaDiv
            type="textbox"
            ref={ref}
            errors={errors}
            name={name}
            id={id}
            onChange={onChange}
        />
    </FormGroupContainer>
    );
}

export const DateInput = ({ref, label, selected, name, id, handleChange, type, errors, ...props}) => {

    // fake the date change to look like events
    const onChange = (date) => {
        const e = {
            currentTarget: {
                name: name,
                value: date.getTime()
            }
        };
        handleChange(e);
    };

    return (
        <FormGroupContainer>
            <FormGroupLabel error={errors?.[name]?.message}>
                {label}{errors?.[name]?.message}
            </FormGroupLabel>
            <DateInputContainer>
                <DatePicker
                    customInputRef={ref}
                    id={id}
                    name={name}
                    selected={selected}
                    onChange={onChange}
                    placeholderText={"MM/DD/YYYY"}
                    open={false}
                />
            </DateInputContainer>
        </FormGroupContainer>
    );
};

export const GenericField = React.forwardRef((props, ref) => {
    const {field, handleChange, errors, getValues, setValue, register, control, clearError, initialValue} = props;
    const commonProps = {
        id: field.name,
        label: field.label,
        name: field.name,
        key: field.name,
        handleChange: handleChange
    };

    const optionsWithNameAdded = field.options?.map(option => ({...option, name: field.name}));
    switch (field.type) {
        case "yesNo":
            return (
                <YesNoRadioInput
                    initialValue={initialValue}
                    ref={register}
                    setValue={setValue}
                    errors={errors}
                    getValues={getValues}
                    {...commonProps}
                />
            );
        case "radio":
            return (
                <MultiSelect
                    radio={true}
                    type='select'
                    initialValue={initialValue}
                    ref={register}
                    options={optionsWithNameAdded}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    {...commonProps}
                />
            );
        case "checkbox":
            return (
                <MultiSelect
                    type='select'
                    ref={register}
                    initialValue={initialValue}
                    options={optionsWithNameAdded}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    {...commonProps}
                />
            );
        case "date":
            return (<DateInput
                radio={true}
                handleChange={handleChange}
                ref={ref}
                errors={errors}
                {...commonProps}
            />);
        case "singleSelect":
            return (
                <SingleSelect
                    ref={register}
                    options={field.options}
                    errors={errors}
                    setValue={setValue}
                    clearError={clearError}
                    {...commonProps}
                />
            );
        case "textArea":
            return (
                <TextAreaInput
                    ref={register}
                    errors={errors}
                    setValue={setValue}
                    clearError={clearError}
                    {...commonProps}
                />
            );
        default:
            return (
                <Controller
                    as={FormGroup}
                    control={control}
                    ref={ref}
                    placeholder={field.placeholder}
                    error=""
                    errors={errors}
                    {...commonProps}
                />
            );
    }
});