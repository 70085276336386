import React, {useContext} from "react";
import ImagePageWithContent from "../common-components/ImagePageWithContent";
import notificationsImage from "../images/notifications-image.svg";
import {MultiSelect} from '../common-components/inputs'
import {PrimaryButton} from "../common-components/buttons";
import {useHistory} from "react-router-dom";
import {useForm} from 'react-hook-form';
import {notificationOptions} from '../form-config';
import {AppContext} from "../store";
import {db} from "../firebase";

export default ({...props}) => {
    const history = useHistory();
    const [globalState, globalDispatch] = useContext(AppContext);
    const { register, setValue, getValues, handleSubmit, errors, unregister} = useForm();


    React.useEffect(()=> {
        register({name: "notification"}, {required: "Please select an option below" });
        return () => {
            unregister("notification");
        }
    }, [register]);

    const onSubmit = async (value, e) => {
        await db.collection('users').doc(globalState.user).update({
            "profile.notifications": value.notification
        });
        history.push("/register/clinics");
    };

    return (
        <ImagePageWithContent
            imageSource={notificationsImage}
            callToActionHeader="Notifications Setup"
            callToActionText="How often would you like to hear from us?"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <MultiSelect
                    name="notification"
                    id="notification"
                    ref={register}
                    options={notificationOptions}
                    setValue={setValue}
                    getValues={getValues} 
                    errors={errors}
                    radio={true}
                />
                <PrimaryButton
                >
                    Continue
                </PrimaryButton>
            </form>
        </ImagePageWithContent>
    );
};
