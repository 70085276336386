import ImagePageWithContent from "../common-components/ImagePageWithContent";
import React, {createContext, useContext, useEffect, useReducer, useRef, useState} from "react";
import enterPhoneImage from '../images/registration.svg'
import verificationCodeImage from '../images/verification-code.svg'

import {Card, colors} from "../common-components/shared-styles";
import {PrimaryButton, SecondaryButton} from '../common-components/buttons';
import styled, {css} from "styled-components";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import {useHistory} from "react-router-dom";
import {db, analytics} from '../firebase';
import * as firebase from "firebase";
import {AppContext} from "../store";

const authCopy = {
    register: {
        header: "Registration",
    },
    login: {
        header: "Login to your Account",
    },
    deleteAccount: {
        header: "Confirm your phone number to delete your account"
    }
};

const getHeader = ({page, type}) => {
    if (page !== "ENTER") {
        return "Verification"
    } else {
        if (type === "LOGIN") {
            return authCopy.login.header;
        } else if (type === "DELETE_ACCOUNT") {
            return authCopy.deleteAccount.header;
        } else {
            return authCopy.register.header;
        }
    }
};

const getText = ({page, phone}) => {
    if (page === "ENTER") {
        return "Enter your mobile number to receive a verification code"
    } else {
        return `Enter the 6 digit number that was sent to ${phone}`
    }
};

const VerificationNumberInput = styled.input`
    background : #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #6C63FF5C;
    border-radius: 16px;
    color: #495057;
    font-size: 3em;
    text-align: center;
    font-family: monospace;
    letter-spacing: .2em;
`;

const phoneNumberStyles = {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "2px solid #6C63FF5C",
    height: "50px",
    width: "100%",
    borderRadius: "16px",
};

const commonContainerCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const VerifyContainer = styled.div`
  ${commonContainerCss};
`;

const EnterPhoneContainer = styled.div`
  ${commonContainerCss};
`;

const PhoneInputForm = styled.form`
  ${commonContainerCss};
  padding: 1em;
`;

const ErrorMessage = styled.span`
  color: ${colors.danger};
`;

const EnterPhoneNumber = ({...props}) => {
    const [globalState, globalDispatch] = useContext(AppContext);
    const [state, dispatch] = useContext(PhoneInputContext);
    const [error, setError] = useState(null);
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            let confirmationResult = await signIn();
            analytics.logEvent('phone_number_entered', {userType: globalState.userType, authType: state.type});
            dispatch({type: "SUBMIT_PHONE", confirmationResult});
        } catch(authError){
            analytics.logEvent('phone_number_exception', {message: authError.message});
            if (authError.message === "TOO_SHORT") {
                setError("Phone number is too short");
            } else {
                console.log(authError);
                setError("Phone Number is invalid");
            }
        }
    };

    const handlePhoneChange = (phone) => {
        dispatch({type: "ENTER_PHONE", phone});
        setError(null);
    };

    let recaptchaRef = useRef({});

    useEffect(() => {
        if (!recaptchaRef.current.recaptchaVerifier) {
            recaptchaRef.current.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': function (response) {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    onSignInSubmit();
                }
            });

            recaptchaRef.current.recaptchaVerifier.render().then(function (widgetId) {
                recaptchaRef.current.recaptchaWidgetId = widgetId;
            });
        }
    });

    useEffect(() => {
        if(globalState.user && state.type === 'LOGIN'){
            history.push("/home");
        }
    }, [globalState.user]);


    const signIn = async () => {
        // THIS ALWAYS PREPENDS A 1, CHANGE IT FOR I18N
        return await firebase.auth().signInWithPhoneNumber(`+1${state.phone}`, recaptchaRef.current.recaptchaVerifier);
    };


    return (
        <EnterPhoneContainer>
            <Card>
                <PhoneInputForm onSubmit={handleSubmit}>
                    {error && <ErrorMessage> {error} </ErrorMessage>}
                    <PhoneInput
                        inputStyle={phoneNumberStyles}
                        isValid={(value) => value.length === 10}
                        country={'us'}
                        onlyCountries={['us']}
                        onChange={handlePhoneChange}
                        value={state.phone}
                        disableCountryCode={true}
                        placeholder={'(XXX)-XXX-XXXX'}
                        // This is working as intended, but needs to fix placeholder
                    />
                    <br/>
                    <PrimaryButton>
                        Get Code
                    </PrimaryButton>
                    <div id='recaptcha-container'/>
                </PhoneInputForm>
            </Card>
        </EnterPhoneContainer>
    );
};


const userExists = async (user) => {
    const userRef = await db.collection('users').doc(user.uid).get();
    return userRef.exists;
};

const deleteAccount = async (user) => {
    await db.collection('users').doc(user.uid).delete();
    await user.delete();
};


// assumes user does not exist, will overwrite if it does
const createAccount = async (user) => {
    await db.collection('users').doc(user.uid).set(
        {
            uid: user.uid,
            phone: user.phoneNumber
        }
    )
};

const Verify = ({...props}) => {
    const [state, dispatch] = useContext(PhoneInputContext);
    const [error, setError] = useState(null);
    const history = useHistory();

    const [globalState, globalDispatch] = useContext(AppContext);
    const handleSubmit = async (e) => {
        e.preventDefault();
        let result;
        try{
            result = await state.confirmationResult.confirm(state.verificationCode);
        } catch(verifyError){
            analytics.logEvent('verification_code_error', {error: verifyError.message})
            setError("The provided code is invalid, try again")
            return;
        }

        const exists = await userExists(result.user);
        if (state.type === "LOGIN") {
            if(exists){
                globalDispatch({
                    type: 'USER_LOGGED_IN',
                    user: result.user.uid
                });
                history.push("/home");
            } else {
                await createAccount(result.user);
                analytics.logEvent('account_created', {userType: globalState.userType});
                globalDispatch({
                    type: 'USER_CREATED',
                    user: result.user.uid
                });
                history.push("/account-not-found")
            }
        } else if (state.type === "DELETE_ACCOUNT") {
            await deleteAccount(result.user);
            analytics.logEvent('account_deleted', {userType: globalState.userType});
            globalDispatch({type: 'SIGN_OUT'});
            history.push('/delete-account/success');
        } else {
            if(exists){
                globalDispatch({
                    type: 'USER_LOGGED_IN',
                    user: result.user.uid
                });
                history.push(`/register/account-exists`)
            } else {
                await createAccount(result.user);
                globalDispatch({
                    type: 'USER_CREATED',
                    user: result.user.uid
                });
                if(globalState.userType === 'volunteer'){
                    history.push("/register/volunteer");
                } else {
                    history.push("/register/demographics");
                }
            }
        }
    };

    const handleVerificationChange = (e) => {
        e.preventDefault();
        dispatch({
            type: "VERIFICATION_CHANGE",
            verificationCode: e.target.value
        })
    };

    return (
        <VerifyContainer>
            <Card>
                <PhoneInputForm onSubmit={handleSubmit}>
                    {error && <ErrorMessage> {error} </ErrorMessage>}
                    <VerificationNumberInput
                        inputmode="numeric"
                        pattern="[0-9]*"
                        autocomplete="one-time-code"
                        onChange={handleVerificationChange}
                        value={state.verificationCode}
                    />
                    <br/>
                    <br/>
                    <PrimaryButton isDanger={state.type === "DELETE_ACCOUNT"}>
                        {state.type === "DELETE_ACCOUNT" ? "Delete Account" : "Verify"}
                    </PrimaryButton>
                    <br/>
                    {error &&
                    <SecondaryButton onClick={(e) => {e.preventDefault(); dispatch({type: "GET_ANOTHER_CODE"})}}>
                        Get another code
                    </SecondaryButton>}
                </PhoneInputForm>
            </Card>
            <br/>
        </VerifyContainer>
    )
};

const phoneInputReducer = (state, action) => {
    switch (action.type) {
        case "ENTER_PHONE":
            return {
                ...state,
                phone: action.phone
            };
        case "SUBMIT_PHONE":
            return {
                ...state,
                confirmationResult: action.confirmationResult,
                page: "VERIFY"
            };
        case "VERIFICATION_CHANGE":
            return {
                ...state,
                verificationCode: action.verificationCode
            };
        case "GET_ANOTHER_CODE":
            return {
                ...state,
                page: "ENTER",
            };
        default:
            console.log(`Unhandled action of type: ${action.type}`);
            return state;
    }
};

const PhoneInputContext = createContext(null);

const onSignInSubmit = () => {
};

export default ({authType, ...props}) => {
    const [state, dispatch] = useReducer(phoneInputReducer, {page: "ENTER", phone: "", type: authType});
    return (
        <PhoneInputContext.Provider value={[state, dispatch]}>
            <ImagePageWithContent
                imageSource={state.page === "ENTER" ? enterPhoneImage : verificationCodeImage}
                callToActionHeader={getHeader(state)}
                callToActionText={getText(state)}
            >
                {state.page === 'ENTER' &&
                <EnterPhoneNumber/>
                }

                {state.page === 'VERIFY' &&
                <Verify/>
                }
            </ImagePageWithContent>
        </PhoneInputContext.Provider>
    )
}
