import React, {useContext} from 'react';
import ImagePageWithContent, {Page} from "../common-components/ImagePageWithContent";
import {PrimaryButton, SecondaryButton} from "../common-components/buttons";
import volunteerStartImage from "../images/volunteer-cover.png"
import {useHistory} from "react-router-dom";
import {AppContext} from "../store";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  //height: 100%;
`;

const headerText = <span>
    Thank you for your interest in joining the <b> myCovidMD® Volunteer
    Task Force.</b> Out of many, there are one. One movement to defeat the coronavirus
    pandemic worldwide and leave no community left behind. In order to reach this
    ambitious goal, we need as much info about you in order to best match
    your skillsets with our growing list of needs. Please complete <u>all</u> the
    questions listed.
</span>

const HeaderTextContainer = styled.div`
  margin-top: 5vh;
  margin-bottom: 5vh;
`

const VolunteerImageContainer = styled.img`
    width: 100%;
`

const VolunteerImage = () => {
    return (
        <VolunteerImageContainer src={volunteerStartImage}/>
    )
}

const Content = styled.div`
  width: 80%;
  //flex-basis: 40%;
`;

const ExtendedPage = styled(Page)`
  height: 85vh;
  justify-content: space-evenly;
`

export default ({}) => {

    const [_state, dispatch] = useContext(AppContext);
    const history = useHistory();
    const navigate = (userType) => {
        dispatch({type: "SET_USER_TYPE", userType});

    };
    return (
        <ExtendedPage>
            <VolunteerImage/>
            <Content>
                <HeaderTextContainer>
                    {headerText}
                </HeaderTextContainer>
                <ButtonContainer>
                    <PrimaryButton
                        onClick={() => history.push('/register')}>
                        Create Account
                    </PrimaryButton>
                    <br/>
                    <SecondaryButton
                        onClick={() => history.push('/login')}>
                        Login
                    </SecondaryButton>
                </ButtonContainer>
            </Content>
        </ExtendedPage>
    )
}
