import NavWrapper from "../common-components/NavWrapper";
import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {AppContext} from "../store";
import {db} from "../firebase";
import {PrimaryButton, SecondaryButton} from "../common-components/buttons";
import styled from 'styled-components';
import {LoadingIcon} from "../common-components/Loader";
import GenericForm from "../common-components/GenericForm";
import {patientInfoForm} from "../forms/patient-info";
import {volunteerForm} from "../forms/volunteer";


const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProfileInfo = ({profile, isVolunteer, toggleEditing, ...props}) => {
    const history = useHistory();

    if(profile) {
        return (
            <ProfileInfoContainer>
                <div>{profile.name}</div>
                <div>
                    <div>{profile?.address}</div>
                    <div>{profile?.city}, {profile?.state?.value} {profile.zip}</div>
                </div>
                <PrimaryButton onClick={toggleEditing}>
                    Edit Profile
                </PrimaryButton>
                <br/>
                {!isVolunteer && <SecondaryButton onClick={() => history.push("/register/volunteer")}>
                    Become A Volunteer
                </SecondaryButton>}
            </ProfileInfoContainer>
        )
    } else {
        toggleEditing();
        return (<ProfileInfoContainer/>)
    }
};

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;
`;

const Profile = ({...props}) => {
    const [editing, setEditing] = useState(false);
    const [profileState, setProfileState] = useState({});
    const [state, dispatch] = useContext(AppContext);
    const [fields, setFields] = useState([]);

    useEffect(() => {
        setProfileState(state?.userInfo?.profile || {});
        let fieldsWithDupes = []
        if(state?.userInfo?.profile){
            fieldsWithDupes = [...fieldsWithDupes, ...patientInfoForm];
        }
        if(state?.userInfo?.volunteerProfile) {
            fieldsWithDupes = [...fieldsWithDupes, ...volunteerForm];
        }
        const fieldsSet = new Set(fieldsWithDupes);
        const fields = Array.from(fieldsSet.values());
        setFields(fields);
    }, [state.userInfo]);

    const toggleEditing = () => {setEditing(!editing)};

    const onSubmit = async (value, e) => {
        await db.collection('users').doc(state.user).update({
            profile: value
        });
        toggleEditing();
    };

    return (
        <NavWrapper>
            <ProfileContainer>
                {!state.userInfo && <LoadingIcon/>}
                {state.userInfo && (editing ?
                    <GenericForm
                        fields={fields}
                        onSubmit={onSubmit}
                    /> :
                    <ProfileInfo
                        toggleEditing={toggleEditing}
                        isVolunteer={!!state?.userInfo?.volunteerProfile}
                        profile={profileState}/>
                    )}
            </ProfileContainer>
        </NavWrapper>
    )
};

export default Profile