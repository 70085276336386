import React, {useContext} from "react";
import ImagePageWithContent from "../common-components/ImagePageWithContent";
import notificationsImage from "../images/notifications-image.svg";
import {MultiSelect} from '../common-components/inputs';
import {PrimaryButton} from '../common-components/buttons';
import {useHistory} from "react-router-dom";
import {useForm} from 'react-hook-form';
import {AppContext} from "../store";
import {db} from "../firebase";

const clinicOptions = [
    {name: 'clincs', value: "covidMed", label: "CovidMed", description: "telemedicine services (medical provider for general medical needs/illnesses from a doctor, physician's assistant or nurse practitioner)"},
    {name: 'clincs', value: "covidPsych", label: "CovidPsych", description: " telehealth services related to psychiatric care from a licensed social worker, nurse practitioner, or psychiatrist"},
    {name: 'clincs', value: "covidWell", label: "CovidWell", description: "wellness services (meditation, prayer, life coaching, emotional support)"},
];

export default ({...props}) => {
    const history = useHistory();
    const [globalState, globalDispatch] = useContext(AppContext);
    const { register, setValue, getValues, handleSubmit, errors, unregister} = useForm();


    React.useEffect(()=> {
        register({name: "clincs"}, {required: "Please select an option below" });
        return () => {
            unregister("clincs");
        }
    }, [register]);

    const onSubmit = async (value, e) => {
        await db.collection('users').doc(globalState.user).update({
            "profile.clinics": value.clincs
        });
        history.push("/register/thank-you");
    };


    return (
        <ImagePageWithContent
            imageSource={notificationsImage}
            callToActionHeader="Choose CovidMD Clinic"
            callToActionText="Choose your desired covidMD clinic"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <MultiSelect
                    name="clincs"
                    id="clincs"
                    ref={register}
                    options={clinicOptions}
                    setValue={setValue}
                    getValues={getValues} 
                    errors={errors}
                    radio={false}
                />
                <PrimaryButton>
                    Continue
                </PrimaryButton>
            </form>
        </ImagePageWithContent>
    );
};
