import patientLogo from "../images/patients-logo.svg";
import volunteerLogo from "../images/volunteer-logo.svg";
import testingImageLogo from "../images/testing-locations.svg";
import backgroundImage from '../images/commyounity.png'

import React, {useContext} from "react";
import styled from 'styled-components';
import {useHistory} from "react-router-dom";
import {AppContext} from "../store";
import {PrimaryButton} from "../common-components/buttons";
import {colors} from "../common-components/shared-styles";

const SelectUserButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 1080px;
  justify-content: space-evenly;
`;

const SelectAvatar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
`;

const AvatarContainer = styled.div`
  flex-basis: 30%;
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const PatientAvatar = (props) => {
    return (
        <AvatarContainer {...props}>
            <img src={patientLogo}/>
            <span> I'm a Patient </span>
        </AvatarContainer>
    )
};

const TestingLocationsImage = (props) => {
    return (
        <AvatarContainer {...props}>
            <img src={testingImageLogo}/>
            <span> Show me Events </span>
        </AvatarContainer>
    )
};

const VolunteerAvatar = (props) => {
    return (
        <AvatarContainer {...props}>
            <img style={{backgroundColor: "#999999", borderRadius: "30px", width: "83px"}} src={volunteerLogo}/>
            <span> I'm a Volunteer </span>
        </AvatarContainer>
    )
};

const LandingImage = styled.img`
  width: 95%;
  max-height: 50vh;
  border-radius: 9px;
  margin: .5em;
  
  @media(max-width: 600px){
    object-fit: contain;
  }
  
  @media(min-width: 600px){
    object-fit: contain;
  }
`;

const SelectUserTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 95vh;
  
  text-align: center;
`;

const PrivacyPolicy = styled.a`
  position: fixed;
  bottom: 1em;
  color: ${colors.lighterText};
`;

export default ({props}) => {
    const [state, dispatch] = useContext(AppContext);
    const history = useHistory();
    const navigatePatient = () => {
        dispatch({type: "SET_USER_TYPE", userType: 'patient'});
        history.push('/get-started');
    };

    const navigateVolunteer = () => {
        dispatch({type: "SET_USER_TYPE", userType: 'volunteer'});
        history.push('/volunteer-start');
    };

    return (

        <SelectUserTypeContainer>
            <LandingImage src={backgroundImage}/>
            <br/>
            <SelectUserButtons>
                <PrimaryButton onClick={navigatePatient}>
                    I'm a patient
                </PrimaryButton>
                <br/>
                <PrimaryButton onClick={navigateVolunteer}>
                    I'm a volunteer
                </PrimaryButton>
                <br/>
                <PrimaryButton onClick={() => history.push('/events')}>
                    Show me events
                </PrimaryButton>
            </SelectUserButtons>
            <PrivacyPolicy href="https://sharedharvestfund.org/terms-of-service/"> Privacy Policy </PrivacyPolicy>
        </SelectUserTypeContainer>

    );
};
