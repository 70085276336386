import {createContext} from "react";
import {db} from "./firebase";

export const AppContext = createContext(null);

export const initialState = {authChecked:false};
export const appReducer = (state, action) => {
    switch(action.type){
        case "SET_USER_TYPE":
            return {
                ...state,
                userType: action.userType
            };
        case "USER_CREATED":
            return {
                ...state,
                user: action.user
            };
        case "USER_LOGGED_IN":
            return {
                ...state,
                authChecked: true,
                user: action.user
            };
        case "AUTH_CHECKED_NO_USER":
            return {
                ...state,
                authChecked: true
            };
        case "SUBSCRIBED_TO_USER_UPDATES":
            return {
                ...state,
                subscribedToUser: true,
                unsubscribe: action.unsubscribe
            };
        case 'USER_INFO_UPDATED':
            return {
                ...state,
                userInfo: action.userInfo
            };
        case "SIGN_OUT":
            state.unsubscribe && state.unsubscribe();
            return initialState;
        default:
            throw new Error(`Whoops, can't handle action: ${action.type}`);
    }
};
