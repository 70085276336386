import {commonInfo} from "./common-info";

export const volunteer = {
    story: {
        name: "story",
        label: "Do you have a Covid-19 story? We would love to hear it.",
        type: "textArea"
    },
    textNotification: {
        name: "textNotification",
        label: "Would you like to opt-in to text notifications? (Think upcoming events, special volunteer rewards, etc.)",
        type: "yesNo",
        required: true
    },
    ageRange: {
        name: "ageRange",
        label: "What age range do you fall under?",
        type: "singleSelect",
        required: true,
        options: [
            {value: "15-17", label: "15-17"},
            {value: "18-24", label: "18-24"},
            {value: "25-35", label: "25-35"},
            {value: "36-45", label: "36-45"},
            {value: "46-55", label: "46-55"},
            {value: "56-60", label: "56-60"},
            {value: "61-65", label: "60-65"},
            {value: "66+", label: "66+"},
        ]
    },
    profession: {
        name: "profession",
        label: "What is your profession? Do you have special talents/skills that we should be aware of?",
        type: "textArea",
        required: true,
        pattern: /.*\S.*/
    },
    locationType: {
        name: "locationType",
        label: "What geographic location would you like to serve?",
        required: true,
        type: "checkbox",
        options: [
            {value: "brooklyNY", label: "Brooklyn, New York"},
            {value: "chicagoIL", label: "Chicago, Illinois"},
            {value: "detroitMI", label: "Detroit, Michigan"},
            {value: "houstonTX", label: "Houston, Texas"},
            {value: "losAngelesCA", label: "Los Angeles, California"},
            {value: "miamiFL", label: "Miami, Florida"},
            {value: "other", label: "Other locations of interest"},
        ],
        validate: (value) => {return value.length !== 0}
    },
    languages: {
        name: "languages",
        label: "Do you speak any languages other than English? If so, please let us know which languages and whether you are fluent or conversational.",
        type: "textArea",
        placeholder: "Please enter each language you speak separated by comma.",
        required: true,
        pattern: /.*\S.*/
    },
    timeCommitment: {
        name: "timeCommitment",
        label: "How much time can you dedicate per week?",
        type: "singleSelect",
        required: true,
        options: [
            {value: "<5", label: "Less than 5 hours per week"},
            {value: "5-10", label: "5-10 hours per week"},
            {value: "10-15", label: "10-15 hours per week"},
            {value: ">15", label: "More than 15 hours per week"},
        ]
    },
    localOrRemotePreference: {
        name: "localOrRemote",
        label: "Does it matter whether the activity is local or remote?",
        type: "yesNo",
        required: true
    },
    covidTestingStatus: {
        name: "covidTestingStatus",
        label:"Have you already been tested for Covid-19? This information will be used for placement purposes only.",
        type: "singleSelect",
        required: true,
        options: [
            {value: "testedPositive", label: "Yes I've had it."},
            {value: "testednegative", label: "Yes, but I was negative"},
            {value: "unknown", label: "No I have not been tested"},
        ]
    },
    rewardType: {
        name: "rewardType",
        label:"Please select your volunteer reward preference.",
        type: "singleSelect",
        required: true,
        options: [
            {value: "studentDebtRelief", label: "Student Loan Debt Relief", description: "Credits accrued will be applied to your student loan balance(s)."},
            {value: "savingsDeposit", label: "Emergency Savings Deposit", description: "Credits accrued will be deposited into your personal emergency fund account."},
            {value: "notApplicable", label: "Not Applicable"}
        ]
    },
    preferredPopulationOptions: {
        name: "preferredPopulationOptions",
        label: "Which population(s) would you like most to serve? This will inform our future expansion initiatives.",
        type: "checkbox",
        options: [
            {value: "elderly", label: "Elderly"},
            {value: "youngAdults", label: "Young Adults"},
            {value: "children", label: "Children"},
            {value: "homeless", label: "Homeless Population"},
            {value: "prison", label: "Prison Population"},
            {value: "immigrant", label: "Immigrant Population"},
        ],
    },
    preferredClinicSupport: {
        name: "preferredClinicSupport",
        label: "Which myCovidMD program would you like to support?",
        type: "checkbox",
        required: true,
        options: [
            {value: "covidPsych", label:"CovidPsych", description: "Virtual Crisis Coach"},
            {value: "covidMed", label: "CovidMed", description:"Pop-up Testing and Outreach Teams"},
            {value: "covidWell", label: "CovidWell", description:"Tele-Wellness Provider (life/spiritual coaching, etc.)"},
        ],
        validate: (value) => {return value.length !== 0}
    },
    nonclicalPositionInterest: {
        name: "nonclicalPositionInterest",
        label: "Please select any nonclincal positions of interest. Leave empty if no preference.",
        type: "checkbox",
        options: [
            {value: "technologyAndDesign", label: "Technology & Design"},
            {value: "advertisingAndMarketing", label: "Advertising & Marketing"},
            {value: "consentWriter", label: "Content Writer"},
            {value: "socialMediaOptimization", label: "Social Media Optimization"},
            {value: "filmAndProduction", label: "Film & Production"},
            {value: "humanResourcesSupport", label: "Human Resources Support"},
            {value: "fundraisingGrantWriting", label: "Fundraising/Grant Writing"},
            {value: "logisticsSupplyChain", label: "Logistics & Suport"},
            {value: "medicalEquipmentResearch", label: "Medical Research Equipment"},
            {value: "prAndNewsletter", label: "PR & Newsletter"},
            {value: "corporateSponsorships", label: "Corporate Sponsorships"},
            {value: "nonprofitRecruitment", label: "Nonprofit Recruitment"},
            {value: "disabilityAndSpecialTeams", label: "Disablity and Special Teams"},
            {value: "governmentRealtions", label: "Government Relations"},
            {value: "donorCommunication", label: "Donor Communication"},
        ]
    },
    allForTheLoveVolunteer: {
        name: "allForTheLoveVolunteer",
        label: "Are you volunteering as an #AllForTheLove Volunteer?",
        type: "yesNo",
        required: true
    },
};


export const volunteerForm = [
    commonInfo.name,
    volunteer.story,
    commonInfo.email,
    volunteer.textNotification,
    commonInfo.gender,
    commonInfo.zip,
    volunteer.ageRange,
    commonInfo.race,
    volunteer.profession,
    volunteer.locationType,
    volunteer.languages,
    volunteer.timeCommitment,
    volunteer.localOrRemotePreference,
    volunteer.covidTestingStatus,
    volunteer.rewardType,
    volunteer.preferredPopulationOptions,
    volunteer.preferredClinicSupport,
    volunteer.nonclicalPositionInterest,
    volunteer.allForTheLoveVolunteer
];
