import React, { useEffect, useReducer, useState } from 'react';
import { Route, Switch, useParams } from "react-router-dom";
import SelectUserType from "./pages/SelectUserType";
import GetStartedPage from "./pages/GetStartedPage";
import { AppContext, appReducer, initialState } from "./store";
import PhoneAuth from "./pages/PhoneAuth";
import ThankYou from "./pages/ThankYou"
import Demographics from "./pages/Demographics";
import { ScrollToTop } from "./common-components/ScrollToTop";
import Assessment from "./pages/Assessment";
import Notifications from "./pages/Notifications";
import Clinics from "./pages/Clinics";
import Home from "./pages/Home";
import Events from "./pages/Events";
import Settings from "./pages/Settings";
import ViewActivity from "./pages/viewActivity";
import Profile from "./pages/Profile";
import PrivateRoutes from "./common-components/PrivateRoutes";
import AccountExists from "./pages/AccountExists";
import { auth } from './firebase';
import Volunteer from './pages/Volunteer';
import ContactUs from './pages/ContactUs';
import Admin from "./pages/Admin";
import firebase from 'firebase/app';
import {db} from "./firebase";
import {ErrorBoundary} from "./common-components/GALoggingErrorBoundary";
import VolunteerStartPage from "./pages/VolunteerStartPage";

function App() {
    const [state, dispatch] = useReducer(appReducer, initialState);
    const [linkToken, setLinkToken] = useState("");

    useEffect(() => {
        if (!state.user && !state.authChecked) {
            auth.onAuthStateChanged((user) => {
                if (user) {
                    dispatch({
                        type: "USER_LOGGED_IN",
                        user: user.uid,
                    });
                    getLinkToken(user.uid);
                } else {
                    dispatch({
                        type: "AUTH_CHECKED_NO_USER"
                    });
                }
            })
            
        }
    });

    useEffect(() => {
        if (state.user && !state.subscribedToUser) {
            const unsubscribe = db.collection("users").doc(state.user)
                .onSnapshot(function (doc) {
                    dispatch({ type: 'USER_INFO_UPDATED', userInfo: doc.data() });
                });
            dispatch({ type: 'SUBSCRIBED_TO_USER_UPDATES', unsubscribe });
        }
    });

    function GetPlaidLinkToken(userUID){
        let plaidLinkToken = firebase.functions().httpsCallable('generatePlaidLinkToken');
        plaidLinkToken({userID: userUID}).then(function(response){
            if(response.data.status_code == "200"){
                setLinkToken(response.data.link_token)
            }
            else{
                const resp = response
            }
        }).catch(function (error) {
            const code = error.code;
            const message = error.message;
            const details = error.details;
        });
    }


    const getLinkToken = async (userUID) => {
        const link_token = await GetPlaidLinkToken(userUID);
    }

    return (
        <div className="App">
            <ErrorBoundary>
                <ScrollToTop />
                <AppContext.Provider value={[state, dispatch]}>
                    <Switch>
                        <Route exact path="/">
                            <SelectUserType />
                        </Route>
                        <Route path="/get-started">
                            <GetStartedPage />
                        </Route>
                        <Route path="/volunteer-start">
                            <VolunteerStartPage/>
                        </Route>
                        <Route path="/login">
                            <PhoneAuth
                                authType="LOGIN"
                            />
                        </Route>
                        <Route path="/account-not-found">
                            <AccountExists
                                accountExists={false}
                            />
                        </Route>
                        <Route exact path="/register">
                            <PhoneAuth
                                authType="REGISTER"
                            />
                        </Route>
                        <Route path="/register/demographics">
                            <Demographics />
                        </Route>
                        <Route path="/register/notifications">
                            <Notifications />
                        </Route>
                        <Route path="/register/clinics">
                            <Clinics />
                        </Route>
                        <Route path="/register/volunteer">
                            <Volunteer />
                        </Route>
                        <Route path="/register/account-exists">
                            <AccountExists
                                accountExists={true}
                            />
                        </Route>
                        <Route path="/register/thank-you">
                            <ThankYou />
                        </Route>
                        <Route path="/events">
                            <Events />
                        </Route>
                        <Route path="/delete-account/success">
                            <ThankYou thankfulFor="deleted" />
                        </Route>
                        <PrivateRoutes>
                            <Route path="/home">
                                <Home linkToken={linkToken}/>
                            </Route>
                            <Route path="/viewActivity/:email" component={ViewActivity}>
                            </Route>
                            <Route path="/assessment">
                                <Assessment />
                            </Route>
                            <Route path="/thank-you">
                                <ThankYou thankfulFor="assessment" />
                            </Route>
                            <Route path="/settings">
                                <Settings />
                            </Route>
                            <Route path="/profile">
                                <Profile />
                            </Route>
                            <Route exact path="/delete-account">
                                <PhoneAuth
                                    authType="DELETE_ACCOUNT"
                                />
                            </Route>
                            <Route path="/contact-us">
                                <ContactUs />
                            </Route>
                            <Route path="/admin">
                                <Admin />
                            </Route>
                        </PrivateRoutes>
                    </Switch>
                </AppContext.Provider>
            </ErrorBoundary>
        </div>
    );
}

export default App;
