export const genderOptions = [
    {name: 'gender', value: 'female', label: 'Female', description: ""},
    {name: 'gender', value: 'male', label: 'Male' , description: ""},
    {name: 'gender', value: 'other', label: 'Other', description: ""},
    {name: 'gender', value: 'preferNotToSay', label: 'Prefer Not To Say', description: ""}
];

export const raceOptions = [
    {   name:"race",
        value: "white",
        label: "White",
        description: "German, Irish, English, Italian, Polish, French, etc"},
    {
        name:"race",
        value: "hispanic",
        label: "Hispanic, Latino or Spanish origin",
        description: "Mexican, Puerto Rican, Cuban, Salvadoran, etc"
    },
    {
        name:"race",
        value: "black",
        label: "Black or African American",
        description: "African American, Jamaican, Haitian, Nigerian, Ethiopian, Somalian"
    },
    {   name:"race",
        value: "asian",
        label: "Asian",
        description: "Chinese, Filipino, Asian Indian, Vietnamese, Korean, Japanese"},
    {
        name:"race",
        value: "americanIndian",
        label: "American Indian or Alaska Native",
        description: "Navajo nation, Blackfeet tribe, Mayan, Aztec, Native Village"
    },
    {
        name:"race",
        value: "middleEastern",
        label: "Middle Eastern or North African",
        description: "Lebanese, Iranian, Egyptian, Syrian, Moroccan, Algerian etc"
    },
    {
        name:"race",
        value: "nativeHawaiian",
        label: "Native Hawaiian or Other Pacific Islander",
        description: "Hawaiian, Samoan, Chamorro, Tongan, Fijian, etc"
    },
    {
        name:"race",
        value: "other",
        label: "Some other race, ethnicity or origin",
        description: ""
    }];

export const religionOptions = [
    { name: "religion", value: "asianFolk", label: "Asian Folk Religion", description:""},
    { name: "religion", value: "hindu", label: "Hindu", description:""},
    { name: "religion", value: "jewish", label: "Jewish", description:""},
    { name: "religion", value: "muslim", label: "Muslim", description:""},
    { name: "religion", value: "christian", label: "Christian", description: "Catholic, protestant or any other Christian denominations"},
    { name: "religion", value: "other", label: "Other", description:""},
    { name: "religion", value: "notReligious", label: "I am not religious", description:""}
];

export const notificationOptions = [
    {name:"notification", value: "twiceDaily", label: "Twice a day"},
    {name:"notification", value: "daily", label: "Daily"},
    {name:"notification", value: "twiceWeekly", label: "Twice a week"},
    {name:"notification", value: "weekly", label: "Weekly"},
];

export const ageOptions = [
    {name:"ageRange", value: "boomer", label: "Baby Boomer (55-75)"},
    {name:"ageRange", value: "genX", label: "Gen X (40-55)"},
    {name:"ageRange", value: "genYMillenial", label: "Gen Y (Millennial) (25-39)"},
    {name:"ageRange", value: "genZ", label: "Gen Z (18-24)"},
    {name:"ageRange", value: "genZ2", label: "Gen Z2 (15-17)"},
]

export const locationTypeOptions = [
    {name:"locationType", value: "urban", label: "Urban"},
    {name:"locationType", value: "suburban", label: "Suburban"},
    {name:"locationType", value: "rural", label: "Rural"},
    {name:"locationType", value: "shelter", label: "Shelter"},
    {name:"locationType", value: "prisons", label: "Prisons"},
];

export const timeCommitmentOptions = [
    {name:"timeCommitment", value: "<10", label: "Less than 10 hours"},
    {name:"timeCommitment", value: "10-15", label: "10-15 hours"},
    {name:"timeCommitment", value: ">15", label: "More than 15 hours"},
    {name:"timeCommitment", value: "notEmployeed", label: "I am not currently employeed"},
];

export const covidTestingOptions = [
    {name:"covidTestingStatus", value: "testedPositive", label: "Yes I've had it."},
    {name:"covidTestingStatus", value: "testednegative", label: "Yes, but I was negative"},
    {name:"covidTestingStatus", value: "unknown", label: "No I have not been tested"},
];

export const rewardTypeOptions = [
    {name:"rewardTypeOptions", value: "studentDebtRelief", label: "Student loan debt relief."},
    {name:"rewardTypeOptions", value: "savingsDeposit", label: "Emergency savings deposit."},
    {name:"rewardTypeOptions", value: "none", label: "None, I would like to donate my time."},
];

export const preferredPopulationOptions = [
    {name:"preferredPopulationOptions", value: "elderly", label: "Elderly"},
    {name:"preferredPopulationOptions", value: "youngAdults", label: "Young Adults"},
    {name:"preferredPopulationOptions", value: "children", label: "Children"},
    {name:"preferredPopulationOptions", value: "homeless", label: "Homeless Population"},
    {name:"preferredPopulationOptions", value: "prison", label: "Prison Population"},
    {name:"preferredPopulationOptions", value: "immigrant", label: "Immigrant Population"},
];

export const preferredClincOptions = [
    {name:"preferredClinicSupport", value: "virtualCrisisCoach", label: "Virtual Crisis Coach (CHP)"},
    {name:"preferredClinicSupport", value: "telemedicineProvider", label: "Telemedicine Provider"},
    {name:"preferredClinicSupport", value: "behaviorHealthProvider", label: "Behavioural Health Provider"},
    {name:"preferredClinicSupport", value: "teleWellnessProvider", label: "Tele-Wellness Provider"},
    {name:"preferredClinicSupport", value: "popupDriveThruClinc", label: "Pop-up Drive-Thru Clinic Team"},
    {name:"preferredClinicSupport", value: "homeVisits", label: "Home Visits Team"},
    {name:"preferredClinicSupport", value: "familyReunification", label: "Child/Family Reunification Team"},
    {name:"preferredClinicSupport", value: "healthAdminstrationSupervisors", label: "Health Administration Supervisors"},
];

export const nonclinicalInterestOptions = [
    {name:"nonclicalPositionInterest", value: "technologyAndDesign", label: "Technology & Design"},
    {name:"nonclicalPositionInterest", value: "advertisingAndMarketing", label: "Advertising & Marketing"},
    {name:"nonclicalPositionInterest", value: "consentWriter", label: "Content Writer"},
    {name:"nonclicalPositionInterest", value: "socialMediaOptimization", label: "Social Media Optimization"},
    {name:"nonclicalPositionInterest", value: "filmAndProduction", label: "Film & Production"},
    {name:"nonclicalPositionInterest", value: "humanResourcesSupport", label: "Human Resources Support"},
    {name:"nonclicalPositionInterest", value: "fundraisingGrantWriting", label: "Fundraising/Grant Writing"},
    {name:"nonclicalPositionInterest", value: "logisticsSupplyChain", label: "Logistics & Suport"},
    {name:"nonclicalPositionInterest", value: "medicalEquipmentResearch", label: "Medical Research Equipment"},
    {name:"nonclicalPositionInterest", value: "prAndNewsletter", label: "PR & Newsletter"},
    {name:"nonclicalPositionInterest", value: "corporateSponsorships", label: "Corporate Sponsorships"},
    {name:"nonclicalPositionInterest", value: "nonprofitRecruitment", label: "Nonprofit Recruitment"},
    {name:"nonclicalPositionInterest", value: "disabilityAndSpecialTeams", label: "Disablity and Special Teams"},
    {name:"nonclicalPositionInterest", value: "governmentRealtions", label: "Government Relations"},
    {name:"nonclicalPositionInterest", value: "donorCommunication", label: "Donor Communication"},
]

export const formatOptions = [
    {name:"preferredFormat", value: "html", label: "HTML"},
    {name:"preferredFormat", value: "plainText", label: "Plain-text"},

]