import { CallToAction, Page } from "../common-components/ImagePageWithContent";
import React, { useContext } from "react";
import styled from "styled-components";
import { useHistory } from 'react-router-dom';
import "../common-components/DatePickerStyles.css"
import { analytics, db } from "../firebase";
import { AppContext } from "../store";
import GenericForm from "../common-components/GenericForm";
import { volunteer, volunteerForm } from "../forms/volunteer";
import { commonInfo } from "../forms/common-info";

const VolunteerPageContainer = styled(Page)`
  padding-bottom: 3em;
`;

const getAppropriateFields = (userInfo) => {
    if (userInfo?.profile) {
        return volunteerForm.filter(field => !commonInfo[field.name]);
    } else {
        return volunteerForm;
    }
};

let userProfileInfo;

const getInitialProfile = (userInfo) => {
    if (userInfo?.profile) {
        const startValues = volunteerForm.reduce((obj, field) => (obj[field.name] = userInfo.profile[field.name], obj), {});
        Object.keys(startValues).forEach(key => {
            if (!startValues[key]) {
                delete startValues[key]
            }
        });
        userProfileInfo = startValues;
    }
    return userProfileInfo
}
export default ({ ...props }) => {
    const [globalState, _] = useContext(AppContext);
    const history = useHistory();

    const onSubmit = async (value, e) => {
        Object.keys(value).forEach(key => {
            if(!value[key]) {
                value[key] = ""
            }
        })
        if(value?.locationType.includes("other")) {
            value.locationType.splice(value.locationType.indexOf("other"), 1);
            value.locationType.push(value.locationType?.other || "")
        }
        await db.collection('users').doc(globalState.user).update({
            volunteerProfile: value
        });
        analytics.logEvent('volunteer_form_completed')
        history.push("/register/thank-you");
    };
    return (
        <VolunteerPageContainer>
            <CallToAction
                header="Volunteer Info"
                text="Thank you for your interest in joining the myCovidMD® Volunteer Task Force. You can become part of the movement to defeat the coronavirus pandemic worldwide that leaves no community behind. In order to reach this ambitious goal, we need as much info about you so that we can best match your skill sets with our growing list of needs in your desired location. Please complete all of the questions listed."
            />
            <GenericForm
                startValues={userProfileInfo || getInitialProfile(globalState.userInfo)}
                fields={getAppropriateFields(globalState.userInfo)}
                onSubmit={onSubmit}
            />
        </VolunteerPageContainer>
    )
}
