import {Page} from "../common-components/ImagePageWithContent";
import NavWrapper from "../common-components/NavWrapper";
import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {Card, colors} from "../common-components/shared-styles";

const ContactUsPageContainer = styled(Page)`
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80%;
`;

const IconInCircleContainer = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5em;
  width: 2em;
  height: 2em;
  align-items: center;
  background: ${colors.primary};
  color: ${colors.white};
  flex: 0 0 2em;
`;

const Icon = styled.i`

`;

const IconInCircle = ({iconClassName, ...props}) => {
    return (
        <IconInCircleContainer>
            <Icon className={iconClassName}/>
        </IconInCircleContainer>
    )
};

const IconCardContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  padding: 1em;
  align-items: center;
  min-height: 100px;
  margin-bottom: 1em;
  width: 100%;
`;

const IconCardContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 1em;
  width: 100%;
`;


const IconCard = ({iconClassName, onClick, ...props}) => {
    return (
        <IconCardContainer onClick={onClick}>
            <IconInCircle iconClassName={iconClassName}/>
            <IconCardContentContainer>
                {props.children}
            </IconCardContentContainer>
        </IconCardContainer>
    )
};

export default () => {

    return (
        <NavWrapper>
            <ContactUsPageContainer>
                <h2>Contact Us</h2>
                <IconCard iconClassName="fas fa-address-card">
                    <b>Headquarters</b>
                    <span>
              <a href="https://goo.gl/maps/wPpugzU2b8DHjms37">
                925 N. La Brea Ave. <br/> Ste# 5059
                <br/>
                Los Angeles, CA 90038
              </a>
              </span>
                </IconCard>
                <IconCard iconClassName="fas fa-envelope-square">
                    <span>
              <b>For general info, email us at</b>
              <br></br>
              <a href="mailto:people@sharedharvestfund.org">people@sharedharvestfund.org</a>
              </span>
                </IconCard>
                <IconCard iconClassName="fas fa-phone">
                    <span>
            <b>Want to speak to a real human?</b>
            <br></br>
              Call: <a href="tel:13238800121">323-880-0121</a>
            </span>
                </IconCard>
            </ContactUsPageContainer>
        </NavWrapper>
    )
};
