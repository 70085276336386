import React, { useContext, useState, useEffect } from 'react';
import NavWrapper from "../common-components/NavWrapper";
import { db, auth } from '../firebase';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { parse } from 'date-fns';
const ViewActivity = (props) => {

    useEffect(() => {
        fetchData()
    }, [])
    const [activities, setActivities] = useState([]);
    const [total, setTotal] = useState(0);
    const fetchData = async () => {
        const postData = [];
        let total = 0;
        await db.collection('Volunteers').where('Email', '==', props.match.params.email).get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    postData.push({ ...doc.data(), documentId: doc.id })
                });
            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
        setActivities(postData);
        postData.forEach((item) => {
            total = total + parseInt(item.eventPoints)
        })
        setTotal(total)
    }

    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
    });


    const classes = useStyles();
    return (
        <NavWrapper >
            <br></br>
            <br></br>
            <h2 style={{ textAlign: 'center' }}>My Volunteer Activity</h2>
            <h4 style={{ textAlign: 'left' }}>Total Points Accrued : {total.toString()}</h4>
            <br></br>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#EBEDEF' }}>
                            <TableCell align="right"><strong>Event Name</strong></TableCell>
                            <TableCell align="right"><strong>Event Location</strong> </TableCell>
                            <TableCell align="right"><strong>Event Date</strong> </TableCell>
                            <TableCell align="right"><strong>Event Sponsor</strong> </TableCell>
                            <TableCell align="right"><strong>Event Points</strong> </TableCell>
                            <TableCell align="right"><strong>Activity Type</strong> </TableCell>
                            <TableCell align="right"><strong>ReceivedPayment?</strong> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {activities.map((row) => (
                            <TableRow key={row}>
                                <TableCell align="right">{row.eventName}</TableCell>
                                <TableCell align="right">{row.eventLocation}</TableCell>
                                <TableCell align="right">{row.Date}</TableCell>
                                <TableCell align="right">{row.eventSponsor}</TableCell>
                                <TableCell align="right">{row.eventPoints}</TableCell>
                                <TableCell align="right">{row.Task}</TableCell>
                                <TableCell align="right">{
                                    row.receivedPayment.toString() == 'true' ? 'Yes' : 'No'
                                }</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </NavWrapper>
    )
}
export default ViewActivity