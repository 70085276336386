import React from "react";
import styled from 'styled-components';
import {commonInputCss, colors} from "./shared-styles";

// Styling

export const FormGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
`;

export const FormGroupLabel = styled.label`
  font-size: 1em;
  margin-bottom: .4em;
  color: ${props => {
    if (props.error) {
        return colors.danger
    } else {
        return colors.lighterText;
    }
}};
`;

export const FormContainer = styled.form`
  width: 80%;
`;

export const FormGroupInput = styled.input`
  ${commonInputCss}
`;

export const FormError = styled.div`
font-size: 1em;
text-transform: capitalize;
margin-bottom: .4em;
color : ${colors.danger};
text-align:center
`;

// Custom form container
export default React.forwardRef((props, ref) => {
    const {name, errors, id, label, type, value, onChange, placeholder} = props;
    return (
        <FormGroupContainer>
            <FormGroupLabel error={errors?.[name]?.message}
                            htmlFor={id}
            >
                {label}{errors?.[name]?.message}
            </FormGroupLabel>
            <FormGroupInput
                ref={ref}
                name={name}
                value={value}
                onChange={onChange}
                type={type}
                id={id}
                placeholder={placeholder}
            />
        </FormGroupContainer>
    )
});