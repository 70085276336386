import {PrimaryButton} from "../common-components/buttons";
import {Page} from "../common-components/ImagePageWithContent";
import NavWrapper from "../common-components/NavWrapper";
import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {auth, db} from "../firebase";
import {useHistory} from "react-router-dom"
import {AppContext} from "../store";
import {colors} from '../common-components/shared-styles'

const SettingsPageContainer = styled(Page)`
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80%;
`;

const PrivacyPolicyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1em;
  padding-bottom: 1em;
`;

const PrivacyPolicyLink = styled.a`
  text-align: center;
  color: ${colors.lighterText};
`;

export const PrivacyPolicy = () => {
    return (
        <PrivacyPolicyContainer>
            <PrivacyPolicyLink href="https://sharedharvestfund.org/terms-of-service/">
                Privacy Policy
            </PrivacyPolicyLink>
        </PrivacyPolicyContainer>
    )
};

export default () => {

    const history = useHistory();
    const [state, dispatch] = useContext(AppContext);
    const [isAdmin, setIsAdmin] = useState(false);

    const logOut = async () => {
        await auth.signOut();
        dispatch({
            type: "SIGN_OUT"
        })
    };

    useEffect(() => {
        const userIsAdmin = async () => {
            if (state.authChecked) {
                if (state.user) {
                    const ref = await db.collection('admins').doc(state.user).get();
                    setIsAdmin(ref.exists);
                }
            }
        };

        userIsAdmin()
    });

    return (
        <NavWrapper>
            <SettingsPageContainer>
                <PrimaryButton
                    onClick={() => history.push("/contact-us")}>
                    Contact Us
                </PrimaryButton>
                <br/>
                <PrimaryButton onClick={logOut}>
                    Log Out
                </PrimaryButton>
                <br/>
                {isAdmin &&
                <PrimaryButton onClick={() => history.push("/admin")}>
                    Admin
                </PrimaryButton>
                }
                <br/>
                <PrimaryButton
                    onClick={() => history.push("/delete-account")}
                    isDanger={true}>
                    Delete Account
                </PrimaryButton>
                <br/>
                <PrivacyPolicy href="https://sharedharvestfund.org/terms-of-service/">Privacy Policy</PrivacyPolicy>
            </SettingsPageContainer>
        </NavWrapper>
    )
};