import React, {useContext, useState} from "react";
import {FormContainer, FormError} from '../common-components/FormGroup';
import { MultiSelect, YesNoRadioInput } from '../common-components/inputs';
import { PrimaryButton} from "../common-components/buttons";
import {CallToAction, Page} from "../common-components/ImagePageWithContent";
import {useHistory} from "react-router-dom"
import styled from "styled-components";
import {useForm} from 'react-hook-form';
import {AppContext} from "../store";
import {db} from "../firebase";
import GenericForm from "../common-components/GenericForm";
import {healthAssessmentForm} from "../forms/health-assessment";
import {analytics} from "../firebase";
import {commonInfo} from "../forms/common-info";

const getAppropriateFields = (userInfo) => {
    if(userInfo?.profile){
        return healthAssessmentForm.filter(field => !commonInfo[field.name]);
    } else {
        return healthAssessmentForm;
    }
};

let userProfileInfo;

const getInitialProfile = (userInfo) => {
    if(userInfo?.profile){
        const startValues = healthAssessmentForm.reduce((obj, field) => (obj[field.name] = userInfo.profile[field.name], obj) ,{});
        Object.keys(startValues).forEach(key => {
            if(!startValues[key]){
                delete startValues[key]
            }
        });
        userProfileInfo = startValues;
    }
    return userProfileInfo
}

export default ({...props}) => {
    const [globalState, globalDispatch] = useContext(AppContext);
    const history = useHistory();

    const onSubmit = async (data) => {
        Object.keys(data).forEach(key => {
            if(!data[key]) {
                data[key] = ""
            }
        })
        await db.collection('users').doc(globalState.user).update({
            assessment: data
        });
        analytics.logEvent('health_assessment_completed');
        history.push("/thank-you");
    };

    return (
        <Page>
            <CallToAction
                header="Assessment Quiz"
                text="Please take the following quiz to help us better serve you"
            />
            <GenericForm
                startValues={userProfileInfo || getInitialProfile(globalState.userInfo)}
                fields={getAppropriateFields(globalState.userInfo)}
                onSubmit={onSubmit}
            />
        </Page>
    )
}
