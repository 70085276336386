import styled, {css, keyframes} from 'styled-components';


export const colors = {
    white: "#FFFFFF",
    primary: "#1E424C",
    unselectedCheckbox: "#DDDFE7",
    lighterText: "#495057",
    focus: "#6C63FF5C",
    danger: "#FF101C",
    success: "#3ACC6C",
    unselectedNavIcon: "#878787"
};

export const commonInputCss = css`
  border-radius: 8px;
  background: ${colors.white};
  color: ${colors.lighterText};
  font-size: 1em;
  padding: calc(0.75em - 1px) calc(0.75em - 1px);
  box-shadow: 3px 3px 25px #0000001C;
  border: 2px solid ${props => props.error ? colors.danger : colors.white};
  outline: none;
  :focus {
      border: 2px solid #6C63FF5C;
  }
`;

export const Card = styled.div`
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 3px 3px 25px #0000001C;
  border-radius: 9px;
  opacity: 1;
`;
