import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Select from "react-select";
import {commonInputCss, colors } from "./shared-styles";
import FormGroup, {FormGroupContainer, FormGroupLabel, FormGroupInput} from './FormGroup';
import {Controller} from "react-hook-form";
const StyledSelect = styled(Select)`
  box-shadow: 3px 3px 25px #0000001C;
`;

const MultiSelectOptionDiv = styled.label`
  ${commonInputCss};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5em;
  padding: calc(0.5em - 1px) calc(0.75em - 1px);
`;

const ColoredGlyph = styled.i`
  color: ${props => {
      if(props.checked) {
          return colors.success;
      } else {
          return colors.unselectedCheckbox;
      }
  }};
  font-size: 1.5em;
`;

const OtherFieldFormWrapperDiv = styled.div`
  display:grid;
`

const CheckMark = ({radio, checked, ...props}) => {
    if (checked) {
        return (
        <ColoredGlyph
            radio={radio}
            checked={checked}
            className="fas fa-check-circle"
        />);
    } else {
        return <ColoredGlyph className="far fa-circle"/>
    }
};

export const SingleSelect = React.forwardRef((props, ref) => {
  const {options, label, errors, name, setValue, clearError} = props;
  const [values, setReactSelectValue] = useState({ selectedOption: [] });
  return (
  <FormGroupContainer>
      <FormGroupLabel error={errors?.[name]?.message}>
          {label}{errors?.[name]?.message}
      </FormGroupLabel>
      <StyledSelect
          ref={ref}
          options={options}
          onChange={(value) => {
              // Manually set values since its not working with updating and react select
              setValue(name, value.label)
              clearError(name);
              setReactSelectValue({ value });
            }
        }
      />
  </FormGroupContainer>)
});

const MultiSelectOptionLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MultiSelectOptionLabel = styled.span`
`;

const MultiSelectOptionDescription = styled.span`
  font-size: .4em;
  color: ${colors.lighterText}
`;

const MultiSelectOption = React.forwardRef((props, ref) => {
    const {option, selected, radio, onChange} = props;
    return (
        <MultiSelectOptionDiv>
            <MultiSelectOptionLabelContainer>
                <MultiSelectOptionLabel>{option.label}</MultiSelectOptionLabel>
                <MultiSelectOptionDescription>{option.description}</MultiSelectOptionDescription>
            </MultiSelectOptionLabelContainer>
           <input
                ref={ref}
                type={radio ? "radio" : "checkbox"}
                id={option.value}
                value={option.value}
                name={option.name}
                checked={selected}
                onClick={onChange}
                defaultChecked={selected}
            />
            <CheckMark
                checked={selected}
                radio={radio}
            />
        </MultiSelectOptionDiv>
    )
});

const MultiSelectContainer = styled.div`
  padding-bottom: 1em;
`;

export const MultiSelect = React.forwardRef((props, ref) => {
    const {label, id, options, radio, errors={}, setValue = () => {}, getValues = () => {}, name, onChange = () => {}, initialValue} = props;
    // let [state, setState] = useState({});
    // if(!Array.isArray(initialValue)) {
    //     [state, setState] = useState({[initialValue] : true});
    // } else {
    let [state, setState] = useState({[initialValue] : true});
    useEffect(() => {
        setState({[initialValue] : true});
    }, [initialValue]);
    if (Array.isArray(initialValue)) {
        // Why the hell does this need to be set to {}, this is not how state should be managed
        // it's very bad practice, but works for initialization of multiple with looping through the values,
        // overall poor design, needs to be reconsidered, most likely has to do with the 4 renders of the form``
        let tempState = {}
        initialValue.forEach(value => {
            tempState[value] = true;
        })
        state = tempState;
    }

    const handleChange = (e) => {
        let value = e.target.value;
        if (radio) {
            // Dirty but works for updating state only on click
            if(state[value] === undefined) {
                setState({[value]: !state[value]});
                setValue(name, value);
            }
        } else {
            const checkboxGroupName = e.target.name;
            let currentValues = getValues(checkboxGroupName) || []
            let checkBoxState = {[value]: !state[value]}
            setState({...state, [value]: !state[value]});
            if(checkBoxState[value]) {
                currentValues.push(value)
            } else {
                currentValues.splice(currentValues.indexOf(value), 1)
            }
            setValue(checkboxGroupName, currentValues);
        }
        onChange(state);
    };
    const handleTextChange = (e) => {
        const checkboxGroupName = e.target.name;
        let currentValues = getValues(checkboxGroupName) || []
        currentValues["other"] = e.target.value;
        setValue(checkboxGroupName, currentValues);
    }
    return (
        <MultiSelectContainer>
            <FormGroupLabel htmlFor={id} error={errors?.[name]}>
                {label}{errors?.[name]?.message}
            </FormGroupLabel>
            <br/>
            {options && options.map(option => {
                return (
                <MultiSelectOption
                    key={option.value}
                    ref={ref}
                    option={option}
                    onChange={handleChange}
                    radio={true}
                    selected={(state && state[option.value]) || false}
                />)
            })}
            {state?.["other"] && 
            <OtherFieldFormWrapperDiv>
                <FormGroupInput
                    type="text"
                    name={name}
                    onChange={handleTextChange}
                    placeholder="Please enter other options here."
                    />
            </OtherFieldFormWrapperDiv>
            }
        </MultiSelectContainer>
    )
});


export const YesNoRadioInput = React.forwardRef((props, ref) => {
  const {id, name, label, setValue, errors, getValues, initialValue} = props;
  const options = [
      {name, value: 'yes', label: 'Yes'},
      {name, value: 'no', label: 'No'}
  ];
  return (
      <MultiSelect
          name={name}
          ref={ref}
          options={options}
          id={id}
          label={label}
          radio={true}
          onChange={()=>{}}
          setValue={setValue}
          errors={errors}
          getValues={getValues}
          initialValue={initialValue}
      />
  )
});

