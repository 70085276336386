import ImagePageWithContent from "../common-components/ImagePageWithContent";
import React, {useContext} from "react";
import accountExistsImage from "../images/registration.svg"
import {PrimaryButton} from "../common-components/buttons";
import {useHistory} from "react-router-dom";
import {AppContext} from "../store";

const copy = {
    accountExists: {
        header: "Account Exists",
        text: "Whoops, looks like this phone number has already been used to create an account, we'll go ahead and log you in",
        action: "Continue To Account",
        nextPage: "/home"
    },
    accountDoesNotExistPatient: {
        header: "Account Not Found",
        text: "We didn't find an account with that information. Let's get one created for you.",
        action: "Continue to create account",
        nextPage: "/register/demographics"
    },
    accountDoesNotExistVolunteer: {
        header: "Account Not Found",
        text: "We didn't find an account with that information. Let's get one created for you.",
        action: "Continue to create account",
        nextPage: "/register/volunteer"
    }
};

export default ({accountExists, ...props}) => {
    const history = useHistory();
    const [globalState, _] = useContext(AppContext);
    const config = accountExists ? copy.accountExists : (globalState.userType === 'volunteer' ? copy.accountDoesNotExistVolunteer : copy.accountDoesNotExistPatient);
    return (
        <ImagePageWithContent
            imageSource={accountExistsImage}
            callToActionHeader={config.header}
            callToActionText={config.text}
        >
            <PrimaryButton
                onClick={() => history.push(config.nextPage)}
            >
                {config.action}
            </PrimaryButton>
        </ImagePageWithContent>
    )
}
