import React, {useContext} from 'react';
import ImagePageWithContent from "../common-components/ImagePageWithContent";
import {PrimaryButton, SecondaryButton} from "../common-components/buttons";
import getStartedImage from "../images/get-started-image.svg"
import {useHistory} from "react-router-dom";
import {AppContext} from "../store";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export default ({}) => {

    const [_state, dispatch] = useContext(AppContext);
    const history = useHistory();
    const navigate = (userType) => {
        dispatch({type: "SET_USER_TYPE", userType});

    };
    return (
        <ImagePageWithContent
            imageSource={getStartedImage}
            callToActionHeader="Get Started"
            callToActionText="Join us now and get free consultation about COVID-19"
        >
            <ButtonContainer>
                <PrimaryButton
                    onClick={() => history.push('/register')}>
                    Create Account
                </PrimaryButton>
                <br/>
                <SecondaryButton
                    onClick={() => history.push('/login')}>
                    Login
                </SecondaryButton>
            </ButtonContainer>
        </ImagePageWithContent>
    )
}
