import {analytics} from "../firebase";
import React from 'react';

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        analytics.logEvent('exception', {error: error.message});
    }

    render() {
        return this.props.children;
    }
}
