import React, {useContext, useEffect} from "react";
import {auth} from "../firebase";
import {useHistory} from "react-router-dom";
import {AppContext} from "../store";

const PrivateRoutes = (props) => {
    const history = useHistory();
    const [state, dispatch] = useContext(AppContext);
    useEffect(() => {
        if(state.authChecked && !state.user){
            history.push("/");
        }
    });

    return props.children;
};

export default PrivateRoutes;