import {commonInfo} from "./common-info";

export const patientInfoForm = [
    commonInfo.name,
    commonInfo.email,
    commonInfo.streetAddress,
    commonInfo.zip,
    commonInfo.state,
    commonInfo.city,
    commonInfo.dateOfBirth,
    commonInfo.gender,
    commonInfo.race,
    commonInfo.religion,
];