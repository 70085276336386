import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {FormContainer, FormError} from "./FormGroup";
import {PrimaryButton} from "./buttons";
import {GenericField} from "./GenericField";
import {PrivacyPolicy} from "../pages/Settings";

const fieldToRegisterArgs = (field) => {
    return [{
        name: field.name,
    }, {
        required: field.required ? "*" : undefined,
        pattern: field.pattern,
        validate: field.validate,
    }]
};

const GenericForm = ({fields, startValues={}, onSubmit}) => {
    const [state, setState] = useState({});
    const {register, setValue, getValues, handleSubmit, errors, clearError, unregister, control} = useForm({defaultValues: startValues});

    React.useEffect(() => {
        fields.map(fieldToRegisterArgs).forEach(args => register(args[0], args[1]));
        return () => fields.map((f) => ({name: f.name})).forEach(unregister);
    },[register],
    // manually setting the values because this is dumb as hell
    Object.keys(startValues).forEach(key => {
        setValue(key, startValues[key])
    }));


    const handleChange = (e) => {
        setState({...state, [e.currentTarget.name]: e.currentTarget.value});
        setValue(e.currentTarget.name, e.currentTarget.value);
    };
    return (
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
            {fields.map(field =>
                <GenericField
                    field={field}
                    initialValue={startValues[field.name]}
                    handleChange={handleChange}
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    clearError={clearError}
                    setValue={setValue}/>)}
            {errors && Object.keys(errors).length > 0 && <FormError>Please submit all required fields.</FormError>}
            <PrimaryButton>
                Continue
            </PrimaryButton>
            <PrivacyPolicy/>
        </FormContainer>
    )
};

export default GenericForm;
